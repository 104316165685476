export const fetchBots = payload => {
  return {
    type: "FETCH_BOTS",
    payload: payload
  };
};

export const setHistoryReducer = payload => {
  return {
    type: "SET_HISTORY",
    payload: payload
  };
};
