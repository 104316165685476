import { CircularProgress, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

import { useStyles } from './styles';

export function SearchInput( { loading = false,onKeyDown, searchRef } ) {
	const classes = useStyles();
	return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				autoFocus
				onKeyDown={onKeyDown}
				onChange={( e ) => searchRef.current = e.target.value}
				placeholder="Id do caso, Autor, Nº do processo..."
				classes={{
					root  : classes.inputRoot,
					input : classes.inputInput,
				}}
				endAdornment={(
					<React.Fragment>
						{loading && <CircularProgress color="inherit" size={20} />}
						{!loading && <span className={classes.esc}>esc</span> }
					</React.Fragment>
				)}
			/>
		</div>
	);
}
