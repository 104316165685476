import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import { getTenantId } from 'util/isAdmin';
import { useRenderPaths } from './hooks/useRenderPaths';
import { useSideNavMenu } from 'context/SideNavMenu';

const SidenavContent = () => {
	const history = useHistory();
	const { renderPath, renderSubPath } = useRenderPaths();
	const { menu: menuItems } = useSideNavMenu();
	const tenantId = getTenantId();

	const closest = ( el, selector ) => {
		try {
			let matchesFn;
			// find vendor prefix
			[
				'matches',
				'webkitMatchesSelector',
				'mozMatchesSelector',
				'msMatchesSelector',
				'oMatchesSelector'
			].some( function( fn ) {
				if ( typeof document.body[fn] === 'function' ) {
					matchesFn = fn;
					return true;
				}
				return false;
			} );

			let parent;

			// traverse parents
			while ( el ) {
				parent = el.parentElement;
				if ( parent && parent[matchesFn]( selector ) ) {
					return parent;
				}
				el = parent;
			}
		} catch ( error ) {
			console.error( 'error', error );
		}

		return null;
	};

	React.useEffect( () => {
		const pathname = `${history.location.pathname}`; // get current path

		const menuLi = document.getElementsByClassName( 'menu' );
		for ( let i = 0; i < menuLi.length; i++ ) {
			menuLi[i].onclick = function( event ) {
				const parentLiEle = closest( this, 'li' );
				if ( menuLi[i].classList.contains( 'menu' ) && parentLiEle !== null ) {
					event.stopPropagation();

					if ( menuLi[i].classList.contains( 'open' ) ) {
						menuLi[i].classList.remove( 'open', 'active' );
					} else {
						menuLi[i].classList.add( 'open', 'active' );
					}
				} else {
					for ( let j = 0; j < menuLi.length; j++ ) {
						const parentLi = closest( this, 'li' );
						if (
							menuLi[j] !== this &&
              ( parentLi === null || !parentLi.classList.contains( 'open' ) )
						) {
							menuLi[j].classList.remove( 'open' );
						} else {
							if ( menuLi[j].classList.contains( 'open' ) ) {
								menuLi[j].classList.remove( 'open' );
							} else {
								menuLi[j].classList.add( 'open' );
							}
						}
					}
				}
			};
		}

		const activeLi = document.querySelector( 'a[href="' + pathname + '"]:not(.app-logo)' ); // select current a element
		try {
			const activeNav = closest( activeLi, 'ul' ); // select closest ul
			if ( activeNav.classList.contains( 'sub-menu' ) ) {
				closest( activeNav, 'li' ).classList.add( 'open' );
			} else {
				closest( activeLi, 'li' ).classList.add( 'open' );
			}
		} catch ( error ) {
			console.error( 'error', error );
		}
	}, [ history.location.pathname ] );

	const renderMenu = () => {
		return menuItems?.map( ( { label: title, icon, url: link, son: subPath } ) => {

			if ( subPath.length > 0 ) {
				return renderSubPath( { subPath, link, icon, title, tenantId } );
			} else if ( link && title ) {
				return renderPath( { link, icon, title } );
			}

			return false;
		} );
	};

	return (
		<CustomScrollbars className=" scrollbar">
			<ul className="nav-menu">
				<li className="nav-header">Menu</li>
				{renderMenu()}
			</ul>
		</CustomScrollbars>
	);
};

export default withRouter( SidenavContent );
