import styled from 'styled-components';

export const NotificationCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: ${props => !props.read ? 'rgba(23, 129, 170, 0.08)' : '#fff' };

  width: 100%;
  padding: 8px;
  gap: 16px;

  .icon-section {
    display: flex;
    justify-content: center;
    align-items: center;

    /* width: ${props => props.isModal ? '20%' : '10%'}; */

    .icon-section__background {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #626263;
      border-radius: 50%;

      width: ${props => props.isModal ? '40px' : '40px'};
      height: ${props => props.isModal ? '40px' : '40px'};
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;

    width: ${props => props.isModal ? '70%' : '80%'};

    gap: 4px;

    .info-section__description {
      font-family: Roboto;
      font-size: ${props => props.isModal ? '11px' : '16px'};
      font-style: normal;
      font-weight: 400;

      .MuiTypography-colorPrimary {
        color: ${props => !props.read ? '#1781AA' : '#808080'};
      }

      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .info-section__date {
      color: #808080;
      font-family: Roboto;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;

      cursor: default;
    }
  }

  .action-section {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 10%;

    .action-section__btn {
      width: 18px;
      height: 18px;

      border-radius: 50%;

      border: solid 1.5px ${props => !props.read ? '#1781AA' : '#808080' };

      background-color: ${props => !props.read ? '#1781AA' : '#fff' };

      :hover {
        cursor: pointer;
      }
    }
  }
`;
