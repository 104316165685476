import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCESS
} from 'constants/ActionTypes';

export const userUpdate = ( user ) => {
  return {
    type    : UPDATE_USER,
    payload : user
  };
};

export const userSignUp = ( user ) => {
  return {
    type    : SIGNUP_USER,
    payload : user
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = ( authUser ) => {
  return {
    type    : SIGNUP_USER_SUCCESS,
    payload : authUser
  };
};

export const userSignInSuccess = ( authUser ) => {
  return {
    type    : SIGNIN_USER_SUCCESS,
    payload : authUser
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};
export const userUpdateSuccess = ( authUser ) => {
  return {
    type    : UPDATE_USER_SUCESS,
    payload : authUser
  };
};

export const showAuthMessage = ( message ) => {
  return {
    type    : SHOW_MESSAGE,
    payload : message
  };
};

export const setInitUrl = ( url ) => {
  return {
    type    : INIT_URL,
    payload : url
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
