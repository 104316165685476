import React, { useState } from 'react';
import Modal from '../Modal';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


import Radio from '@material-ui/core/Radio/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Button from '../Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schemaNps } from './validate';
import TextField from '@material-ui/core/TextField/TextField';
import { npsService } from '../../api/nps';
import { FormControl } from '@mui/material';

const Nps = ( { show, setShow } ) => {
	const { register, handleSubmit, formState: { errors } } = useForm( { resolver: yupResolver( schemaNps ) } );

	const [ value, setValue ] = useState( null );
	const [ step, setStep ] = useState( 1 );

	const handleChange = ( event ) => {
		setValue( event.target.value );
	};

	function submitNps( data ) {
		try {
			npsService.register( data );
			setStep( 3 );
		} catch ( error ) {
			if ( error instanceof Error ) {
				console.log( 'Nps.catch', error.message );
			}

		}
	}


	return (
		<Modal
			titleHeader={''}
			hidden={step !== 3}
			visible={show}
			onClose={() => setShow( old => !old )}>
			<div>
				<form className='d-flex flex-column' onSubmit={handleSubmit( submitNps )}>
					{step === 1 && (
						<>
							<FormControl component="fieldset">
								<FormLabel component="legend" style={{ color: '#343435' }} className='text-center my-3'>Em uma escala de 0 a 10,  qual a probabilidade de você recomendar a Acordo Fechado para um amigo ou conhecido?<span className='text-red'> *</span></FormLabel>
								<RadioGroup style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} aria-label="nota" name="nota1" value={value} onChange={handleChange}>
									<FormControlLabel {...register( 'value' )} value="1" labelPlacement='top' control={<Radio style={{ color: '#DD2E20' }} />} label="1" />
									<FormControlLabel {...register( 'value' )} value="2" labelPlacement='top' control={<Radio style={{ color: '#E23F2F' }} />} label="2" />
									<FormControlLabel {...register( 'value' )} value="3" labelPlacement='top' control={<Radio style={{ color: '#E54837' }} />} label="3" />
									<FormControlLabel {...register( 'value' )} value="4" labelPlacement='top' control={<Radio style={{ color: '#FF7208' }} />} label="4" />
									<FormControlLabel {...register( 'value' )} value="5" labelPlacement='top' control={<Radio style={{ color: '#FF8D12' }} />} label="5" />
									<FormControlLabel {...register( 'value' )} value="6" labelPlacement='top' control={<Radio style={{ color: '#FFBC5E' }} />} label="6" />
									<FormControlLabel {...register( 'value' )} value="7" labelPlacement='top' control={<Radio style={{ color: '#55CE96' }} />} label="7" />
									<FormControlLabel {...register( 'value' )} value="8" labelPlacement='top' control={<Radio style={{ color: '#0CB969' }} />} label="8" />
									<FormControlLabel {...register( 'value' )} value="9" labelPlacement='top' control={<Radio style={{ color: '#08AA56' }} />} label="9" />
									<FormControlLabel {...register( 'value' )} value="10" labelPlacement='top' control={<Radio style={{ color: '#03933B' }} />} label="10" />
								</RadioGroup>
							</FormControl>
							<Button type="button" disabled={!value} style={{ width: '256px', maxWidth: '90%' }} className="mx-auto mt-3" onClick={() => setStep( 2 )}>Próximo</Button>
						</>
					)}
					{step === 2 && (
						<>
							<FormControl >
								<FormLabel component="legend" style={{ color: '#343435' }} className='text-center my-5'>Tem uma sugestão de melhoria? Compartilhe conosco preenchendo o campo abaixo:</FormLabel>
								<TextField
									className='textFieldRegisterClient w-100'
									placeholder='Responda'
									{...register( 'observation' )}
									helperText={errors.observation?.message}
								/>
							</FormControl>
							<div className='d-flex mt-5 justify-content-center' style={{ gap: '12px' }}>
								<Button type="button" variant="Secondary" style={{ width: '256px', maxWidth: '90%' }} onClick={() => setStep( 1 )}>Voltar</Button>
								<Button type="submit" style={{ width: '256px', maxWidth: '90%' }}>Enviar</Button>
							</div>
						</>
					)}
					{step === 3 && (
						<div className='d-flex flex-column justify-content-center align-items-center'>
							<CheckCircleOutlineIcon htmlColor='#0CB969' style={{ fontSize: '110px', marginBottom: '24px' }} />
							<p style={{ fontSize: '20px', color: '#343435', marginBottom: '16px' }}>Recebemos sua avaliação!</p>
							<p style={{ fontSize: '16px', fontWeight: '400', color: '#626263' }}>Obrigado por contribuir com o Acordo Fechado!</p>
						</div>
					)}
				</form>
			</div>
		</Modal>
	);
};

export default Nps;
