export const fetchCases = payload => {
  return {
    type    : 'FETCH_CASES_SAGA',
    payload : payload
  };
};

export const update_mass_edit = payload => {
  return {
    type    : 'UPDATE_CASES_SAGA',
    payload : payload
  };
};

export const mass_editing = payload => {
  return {
    type    : 'MASS_EDIT_SAGA',
    payload : payload
  };
};
