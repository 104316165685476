import { useState } from 'react';

export const useNotificationUtils = () => {
  const [ notificationTabValue, setNotificationTabValue ] = useState( 0 );
  const [seeAllFilter, setSeeAllFilter] = useState( false );

  const handleChangeTabValue = ( event, newValue ) => {
    setNotificationTabValue( newValue );
  };

  const verifyNotificationRender = ( notify ) => {
    const isReportNotify = notify.description.includes( 'Relatório' );
    const isAgreementProtocolRequest = notify.origin === 'petition-without-agreement';
    if ( isReportNotify ) {
      return 'report';
    }

    if ( isAgreementProtocolRequest ) {
      return 'petition';
    }

    return 'default';
  };

  const mountLinkUrl = ( url ) => {
    const splitedUrl = url.split( '/' );
    const splitedUrlLastIndex = splitedUrl.length - 1;
    const queueId = splitedUrl[splitedUrlLastIndex];
    const mountedUrl = `${window.location.href.split( 'app' )[0]}app/peticoes/${queueId}`;
    return mountedUrl;
  };

  const verifyIfHaveNotifications = ( allNotifications ) => {
    if ( allNotifications ) {
      const newNotifications = allNotifications.filter( notice => notice.read === false );
      return newNotifications.length > 0;
    }
  
    return false; 
  };

  return {
    notificationTabValue,
    handleChangeTabValue,
    seeAllFilter,
    setSeeAllFilter,
    verifyNotificationRender,
    verifyIfHaveNotifications,
    mountLinkUrl,
  };
};
