import { useState } from 'react';
import { skeletonLoadingAnimationLtr, skeletonLoadingAnimationRtl } from './styles';

export const useSkeletonAnimation = () => {
	const [ alternateAnimation, setAlternateAnimation ] = useState( skeletonLoadingAnimationLtr );

	const handleAnimateSkeleton = ( duration = 950 ) => {

		const timeoutdId = setTimeout( () => {
			if( alternateAnimation === skeletonLoadingAnimationLtr ) {
				setAlternateAnimation( skeletonLoadingAnimationRtl );
			}
			else {
				setAlternateAnimation( skeletonLoadingAnimationLtr );
			}

		}, ( duration ) );

		return () => clearTimeout( timeoutdId );
	};

	const hookStates = {
		alternateAnimation,
	};

	const hookSetStates = {
		setAlternateAnimation,
	};

	const hookHandlers = {
		handleAnimateSkeleton,
	};

	return {
		hookStates,
		hookSetStates,
		hookHandlers,
	};
};
