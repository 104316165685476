import React from 'react';
import { useDialerContext } from '../DialerContext';
import { DefaultCallActionBtn } from './styles';
import { Mic, MicOff } from '@material-ui/icons';

const MuteBtn = () => {
	const { isMuted, handleMute } = useDialerContext();
	if ( !isMuted ) {
		return (
			<DefaultCallActionBtn onClick={handleMute}>
				<Mic titleAccess='Desativar Microfone' fontSize='small' />
			</DefaultCallActionBtn>
		);
	}
	return (
		<DefaultCallActionBtn onClick={handleMute}>
			<MicOff titleAccess='Ativar Microfone' fontSize='small' />
		</DefaultCallActionBtn>
	);
};

export default MuteBtn;