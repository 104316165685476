import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import clientSagas from './Clients';
import filterCasesSaga from './FilterCases';
import casesSaga from './Cases';
import settingsSaga from './Settings';
import workflowSaga from './Workflow';
import botsSaga from './Bots';
import notifyAlert from './NotifyAlert';

export default function* rootSaga() {
  yield all([
    authSagas(),
    clientSagas(),
    filterCasesSaga(),
    casesSaga(),
    settingsSaga(),
    workflowSaga(),
    botsSaga(),
    notifyAlert()
  ]);
}
