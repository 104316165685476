import React from 'react';
import { useDialerContext } from '../DialerContext';
import { DefaultCallActionBtn } from './styles';
import { Pause, PlayArrow } from '@material-ui/icons';

const PauseBtn = () => {
	const { isPaused, handlePause } = useDialerContext();
	if ( !isPaused ) {
		return (
			<DefaultCallActionBtn onClick={handlePause}>
				<Pause titleAccess='Pausar ligaçao' fontSize='small' />
			</DefaultCallActionBtn>
		);
	}
	return (
		<DefaultCallActionBtn onClick={handlePause}>
			<PlayArrow titleAccess='Voltar ligação' fontSize='small' />
		</DefaultCallActionBtn>
	);
};

export default PauseBtn;