import jwt from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';

import { api } from './createApi';
import { AUTH_ALL, AUTH_UPDATE } from 'constants/Links';
import { store } from '../MainApp';
import { progressActions } from '../actions';
import { authenticationApi } from './createApi';

const auth = {
	getAuth: async function( payload ) {
		try {
			const response = await api.get( `${AUTH_ALL}${payload}` );
			return response;
		} catch ( error ) { console.error( error );}
	},
	updateUser: async function( user ) {
		const data = {
			...user
		};
		if ( !data.id ) {
			data.id = user._id;
		}
		if ( !data._id ) {
			data._id = user.id;
		}
		// console.log(data);
		return await api.put( AUTH_UPDATE, data );
	},

	/* JWT decoders */
	getJwtName: function( token ) {
		try {
			const jwtToken = jwt_decode( token );
			return jwtToken.group_name;
		} catch ( err ) {
			return '';
		}
	},
	getJwtColor: function( ) {
		return '#3F51B5';
	},
	getJwtAccess: function( token ) {
		try {
			var cred = jwt.verify( token, 'secretkey' );
			return cred.userAccess;
		} catch ( err ) {
			return 'CO';
		}
	},
	getJwtLogin: function( token ) {
		try {
			const jwtToken = jwt_decode( token ).id_token;
			const idToken =  jwt_decode( jwtToken );
			return idToken.email;
		} catch ( err ) {
			return '';
		}
	},
	getJwtId: function( token ) {
		try {
			var cred = jwt.verify( token, 'secretkey' );
			console.log( { cred } );
			return cred._id;
		} catch ( err ) {
			return '';
		}
	},
	getJwtTenantId: function( token ) {
		try {
			const jwtToken = jwt_decode( token );
			return jwtToken?.tenant_id;
		} catch ( err ) {
			return '';
		}
	},
	getJwtRamal: function( token ) {
		try {
			var jwt = jwt_decode( token, 'secretkey' );
			if ( !jwt.ramal || Number( jwt.ramal ) === 0 ) {
				return null;
			}
			return jwt.ramal;
		} catch ( err ) {
			return '';
		}
	},
	getUserId: function( token = null ) {
		try {
			const tokenAF = token || localStorage.getItem( 'af-token' );
			var jwt = jwt_decode( tokenAF );
			return jwt.userId;
		} catch ( err ) {
			if ( err instanceof Error ) {
				console.error( 'getUserId', err.message );
				return '';
			}
		}
	},
	getJwtActive: function( token ) {
		try {
			var cred = jwt.verify( token, 'secretkey' );
			return cred.isActive;
		} catch ( err ) {
			return '';
		}
	},
	users: async function( query = '' ) {

		return await authenticationApi.get( `/users?${query}`, {
			headers: {
				'app-origin': 'console'
			}
		} )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	listRoutesByPerfil: async function( { profileId, tenantId } ) {
		return await authenticationApi.get( `pages/profile/${profileId}/company/${tenantId}` );
	},

	listOfProfiles: async function() {
		return await authenticationApi.get( '/list-profiles' )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	register: async function( payload ) {
		return await authenticationApi.post( '/register', payload, {
			headers: {
				'app-origin': 'console'
			}
		} )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	changeUser: async function( payload ) {
		return await authenticationApi.put( '/user', payload )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	changePassword: async function( payload ) {
		return await authenticationApi.put( '/password', payload, {
			headers: {
				'app-origin': 'console'
			}
		} )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	removeUser: async function( payload ) {
		return await authenticationApi.delete( '/remove', { data: payload }, {
			headers: {
				'app-origin': 'console'
			}
		} )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	signInWithEmailAndPassword: async function( payload ) {
		return await authenticationApi.post( '/login/local', payload, {
			headers: {
				'app-origin': 'console'
			}
		} )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	changeExperipCredentials: async function( payload ) {
		return await authenticationApi.patch( '/update-experip-credentials', payload );
	}
};

export { auth };
