import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Container } from './styles';

const Modal = ( { style, closeVideos, children } ) => {
  return (
    <Container>
      <div style={style} className="content-modal">
        <button
          onClick={closeVideos}
          className="bt-close"
        >
          <CloseIcon />
        </button>
        {children}
      </div>
    </Container>
  );
};

export default Modal;
