import React, { useContext, useState } from 'react';

const { createContext } = require( 'react' );

const ProtocolCredentialContext = createContext( {} );

export const ProtocolCredentialProvider = ( { children } ) => {
  const [ openProtocolCredentialModal, setOpenProtocolCredentialModal ] = useState( false );
  const [ openAddCredentialForm, setOpenAddCredentialForm ] = useState( false );
  const [userAction, setUserAction] = useState( false );
  const [caseId, setCaseId] = useState();
  const [protocolContextResponse, setProtocolContextResponse] = useState();

  const handleCloseCredentialForm = () => {
    setOpenAddCredentialForm( false );
  };

  return (
    <ProtocolCredentialContext.Provider value={{
      openProtocolCredentialModal,
      setOpenProtocolCredentialModal,
      openAddCredentialForm,
      setOpenAddCredentialForm,
      handleCloseCredentialForm,
      caseId,
      setCaseId,
      userAction,
      setUserAction,
      protocolContextResponse,
      setProtocolContextResponse
    }}>
      {children}
    </ProtocolCredentialContext.Provider>
  );
};

export function useProtocolCredentialContext() {
  const context = useContext( ProtocolCredentialContext );

  if ( !context ) {
    throw new Error( 'useToast must be used within a ProtocolCredentialContext' );
  }

  return context;
}
