import styled from 'styled-components';

export const Container = styled.div`
    background: rgba(242,243,245, 0.6);


    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 9999;

  .content-modal {
    background: var(--white);

    width: 900px;
    height: fit-content;

    max-width: 90%;
    padding: 2rem 3rem;
    border-radius: 5px;

    box-shadow: 0px 0px 6px rgba(0,0,0,0.16), 0px 0px 49px rgba(0,0,0,0.23);

    text-align: center;

    position: relative;
  }

  .bt-close {
    position: absolute;
    top: 10px;
    right: 10px;

    background: transparent;
    border: 0;
  }

`;
