import axios from 'axios';
import { verifyEnvUrl } from '../../util/verifyEnvUrl';

const PROD_CASES_REGISTER_URL = 'https://api.acordofechado.com.br';
const DEV_CASES_REGISTER_URL = 'https://us-central1-acordo-fechado-dev.cloudfunctions.net/getCasesIdsDev';

const casesIdsApi = axios.create( {
	baseURL: verifyEnvUrl( PROD_CASES_REGISTER_URL, DEV_CASES_REGISTER_URL ),
} );

casesIdsApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default casesIdsApi;
