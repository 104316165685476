const INITAL_STATE = {
  stateSelect: false,
  select: false
};

export default function SelectReducer(state = INITAL_STATE, action) {
  switch (action.type) {
    case "SET_VALUE": {
      let { payload } = action;
      return { ...state, stateSelect: { ...payload.select } };
    }

    case "SET_SELECT": {
      let { payload } = action;

      return { ...state, select: { ...state.select, ...payload } };
    }

    default:
      return state;
  }
}
