import styled from 'styled-components';

export const MinimalRoundedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props => props.disabled ? '#B1B1B1' : '#004E7B'};
  background-color: #fff;

  border: solid 1px ${props => props.disabled ? '#B1B1B1' : '#004E7B'};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  width: fit-content;
  height: 25px;

  padding: 8px;

  border-radius: 4px;

  cursor: ${props => props.disabled ? 'default' : 'pointer'};

  :hover {
    background-color: ${props => !props.disabled && '#1781AA'};
    border-color: ${props => !props.disabled && '#1781AA'};
    color: ${props => !props.disabled && '#fff'};

    opacity: 1;
  }
`;