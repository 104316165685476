import { useNotifyAlert } from 'context/NotifyAlert';
import { useRef, useState } from 'react';

export const useSessionHandlers = ( dialerStatus ) => {
	const { setShowNotify } = useNotifyAlert();
	const [ callInProgress, setCallInProgress ] = useState( false );
	const [ lockContext, setLockContext ] = useState( false );
	const [ isMuted, setIsMuted ] = useState( false );
	const [ isPaused, setIsPaused ] = useState( false );
	const [ isConnecting, setIsConnecting ] = useState( false );
	const [ seconds, setCount ] = useState( 0 );
	const intervalRef = useRef( null );

	const startTimer = () => {
		if ( intervalRef.current !== null ) return;

		const tick = () => {
			setCount( prevCount => prevCount + 1 );
		};

		intervalRef.current = setInterval( tick, 1000 );
	};

	const pauseTimer = () => {
		if ( intervalRef.current !== null ) {
			clearInterval( intervalRef.current );
			intervalRef.current = null;
		}
	};

	const stopTimer = () => {
		if ( intervalRef.current !== null ) {
			clearInterval( intervalRef.current );
			intervalRef.current = null;
			setCount( 0 );
		}
	};

	const getTimerFormatted = () => {
		const minutes = Math.floor( seconds / 60 );
		const remainingSeconds = seconds % 60;
		return `${String( minutes ).padStart( 2, '0' )}:${String( remainingSeconds ).padStart( 2, '0' )}`;
	};

	const onConnect = () => {
		setIsConnecting( true );
	};

	const onStart = () => {
		startTimer();
		setIsConnecting( false );
	};

	const onProgress = () => {
		setCallInProgress( true );
	};

	const onFailed = ( error ) => {
		console.log( error );
		if ( error?.originator !== 'local' ) {
			setShowNotify( {
				status : 'error',
				text   : 'Algo deu errado ao tentar realizar a ligação. Recarregue a página ou entre em contato com o suporte.'
			} );
		}
		if ( !dialerStatus ) {
			setShowNotify( {
				status : 'error',
				text   : 'Discador desconectado, conecte e tente novamente'
			} );
		}
		setCallInProgress( false );
		setLockContext( false );
		setIsConnecting( false );
		stopTimer();
	};

	const onEnd = () => {
		setCallInProgress( false );
		setLockContext( false );
		setIsConnecting( false );
		stopTimer();
	};

	const eventHandlers = {
		connecting : onConnect,
		accepted   : onStart,
		progress   : onProgress,
		failed     : onFailed,
		ended      : onEnd,
	};

	return {
		callInProgress,
		eventHandlers,
		lockContext,
		setLockContext,
		isMuted,
		setIsMuted,
		isPaused,
		setIsPaused,
		getTimerFormatted,
		isConnecting,
		pauseTimer,
		startTimer,
	};
};