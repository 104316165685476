const INITIAL_STATE = {
	persons: {
		persons : [],
		page    : 0,
		pages   : 0
	},
	listPersons  : [],
	filterPerson : '',
	modal        : {
		modalType   : '',
		modalToggle : false,
		type        : false
	},
	loader: false
};

export default function per( state = INITIAL_STATE, action ) {
	switch ( action.type ) {
	case 'ADD_PERSON':{
		// const { rows, page, pages, total } = action.payload
		const rows = action.payload;
		const newPersons = [];
		const newIdPersons = [];

		rows.filter( row => row.is_active ).forEach( person => {
			let is_lawyer = person.is_lawyer ? 'Advogado' : 'Autor';
			let splitMail = person?.email ? person?.email.split( '|' ): '';

			newPersons.push( [ person.name, is_lawyer, splitMail[0], person.cpf, person.telephones ? person.telephones[0] : '-', 'edit', 'del' ] );
			newIdPersons.push( person.id );
		} );

		return { ...state, listPersons: { newPersons, newIdPersons }, persons: { persons: rows } };
	}
	case 'FILTER_PERSON':{
		const { persons, filterPerson, typeSearch } = action.payload;

		const response = persons.persons.filter( ( per ) => {
			let phones = per.telephones ? per.telephones[0] : '';
			let filtList = per.name.toLowerCase() + ' ' + per.email.toLowerCase() + ' ' + per.cpf + ' ' + phones;
			return filtList.indexOf( filterPerson.toLowerCase() ) !== -1;
		} );

		const arrPerson = [];
		response.map( p => p && arrPerson.push( p ) );

		const filterNewPersons = [];
		const filterNewIdPersons = [];
		arrPerson.forEach( person => {

			let typePerson = person.is_lawyer ? 'Advogado' : 'Autor';

			if ( typeSearch === 'all' ) {
				person.is_active &&
            filterNewPersons.push( [ person.name, typePerson, person.email, person.cpf, person.telephones ? person.telephones[0] : '-', 'edit', 'del' ] );
				filterNewIdPersons.push( person.id );
			} else {
				person.is_active && typeSearch === typePerson &&
            filterNewPersons.push( [ person.name, typePerson, person.email, person.cpf, person.telephones ? person.telephones[0] : '-', 'edit', 'del' ] );
				filterNewIdPersons.push( person.id );
			}
		} );

		return { ...state, persons: { persons: persons.persons, page: persons.page, pages: persons.pages, total: persons.total }, listPersons: { newPersons: filterNewPersons, newIdPersons: filterNewIdPersons }, filterPerson: action.payload };
	}
	case 'MODAL_TOGGLE':{
		const { modal } = action.payload;
		return { ...state, modal: { modalToggle: modal.modalToggle, modalType: modal.modalType, type: modal.type } };
	}
	case 'LOADER':
		return { ...state, loader: action.payload };
	default:
		return state;
	}
}
