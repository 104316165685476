import React from 'react';
import { NotificationCardContainer } from './styles';
import { Notifications } from '@material-ui/icons';
import { useNotificationRequests } from '../../hooks/notificationRequests';
import { convertDateAndHour } from 'util/convertDateHour';
import { useNotificationUtils } from '../../hooks/notificationUtils';
import { ReceiptLong } from '@mui/icons-material';
import { Link } from '@material-ui/core';

const NotificationCard = ( { allNotification, notification, isModal, setLoading } ) => {
  const { handleUpdateNotification } = useNotificationRequests();
  const { verifyNotificationRender, mountLinkUrl } = useNotificationUtils();

  const notificationIcons = {
    default  : <Notifications style={{ color: '#fff' }} />,
    report   : <ReceiptLong style={{ color: '#fff' }} />,
    petition : <Notifications style={{ color: '#fff' }} />,
  };

  const linkComponent = {
    default : <Link href='#' onClick={() => handleUpdateNotification( allNotification, notification, setLoading, true )} >{notification.description}</Link>,
    report  : <Link
      href={notification.file}
      target='_self'
      download={true}
      onClick={() => handleUpdateNotification( allNotification, notification, setLoading, true )} >{notification.description}</Link>,
    petition: 
    <Link 
      href={mountLinkUrl( notification.file )} 
      target='_self'
      onClick={() => handleUpdateNotification( allNotification, notification, setLoading, true )}
    >{notification.description}</Link>,
  };

  return (
    <NotificationCardContainer isModal={isModal} read={notification.read} >
      <div className='icon-section'>
        <div className='icon-section__background'>
          {notificationIcons[verifyNotificationRender( notification )]}
        </div>
      </div>
      <div className='info-section'>
        <span className='info-section__description'>{linkComponent[verifyNotificationRender( notification )]}</span>
        <span className='info-section__date'>{convertDateAndHour( notification.createdAt )}</span>
      </div>
      <div className='action-section'>
        <div
          title={notification.read ? 'Marcar como não lida' : 'Marcar como lida'}
          className='action-section__btn'
          onClick={() => handleUpdateNotification( allNotification, notification, setLoading )} />
      </div>
    </NotificationCardContainer>
  );
};

export default NotificationCard;
