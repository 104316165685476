
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( {
	container: {
		backgroundColor : ( props ) => props.checked ? 'rgba(0,0,0,0.3)' : 'transparent',
		position        : 'absolute',
		display         : 'flex',
		height          : '100%',
		zIndex          : 2,
		width           : '100%'
	},
	paper: {
		zIndex          : 2,
		border          : 'none',
		width           : '100%',
		height          : '100%',
		boxShadow       : 'none !important',
		backgroundColor : 'transparent !important',
		position        : 'fixed',
		display         : 'flex',

	},
	itemHistory: {
		backgroundColor : '#ECECEC',
		color           : '#626263',
		display         : 'flex',
		justifyContent  : 'space-between',
		alignItems      : 'center',
		borderRadius    : '6px',
		gap             : '8px',
		padding         : '8px',
		position        : 'relative',
		'& a'           : {
			width : '100%',
			color : '#626263'
		},
		'& button': {
			color  : '#626263',
			border : 'none'
		}
	},

	buttonDelete: {
		right: '8px',
	},
	boxInput: {
		border       : '1px solid #919192',
		width        : '100%',
		borderRadius : '6px'
	},
	paperFooter: {
		display    : 'flex',
		margin     : '16px 0px',
		alignItems : 'center',
		gap        : '14px',
		color      : '#919192',
	},
	box: {
		margin          : 'auto',
		padding         : '16px 16px 16px 16px',
		display         : 'flex',
		backgroundColor : '#fff',
		borderRadius    : '4px',
		gap             : '4px',
		flexDirection   : 'column',
		width           : '550px',
		boxShadow       : '0px 2px 5px 0px rgba(0, 0, 0, 0.26)',
		' & p'          : {
			marginBottom : '0px',
			marginTop    : '8px'
		}
	},
	boxKeyBoard: {
		borderRadius    : '8px',
		border          : '1px solid #E1EAEF',
		backgroundColor : '#FFF',
		boxShadow       : 'rgba( 0, 0, 0, 0.15 ) 2px 2px 2px 0px',
		padding         : '8px',
		width           : '36px',
		height          : '36px',
		textAlign       : 'center',
		display         : 'flex',
		alignItems      : 'center',
		justifyContent  : 'center'
	}
} );
