import React from 'react';
import { Container } from './styles';

const Button = ( { children, ...rest } ) => {
	const propsButton = Object.assign( {}, rest );
	delete propsButton.loading;

	return (
		<Container
			{...propsButton}
			disabled={rest.loading || rest.disabled}>
			{rest.loading ? 'Aguarde...' : children}
		</Container>
	);
};

export default Button;
