export function cpfMask ( value ){
	return value
		.replace( /\D/g, '' ) // substitui qualquer caracter que nao seja numero por nada
		.replace( /(\d{3})(\d)/, '$1.$2' ) // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace( /(\d{3})(\d)/, '$1.$2' )
		.replace( /(\d{3})(\d{1,2})/, '$1-$2' )
		.replace( /(-\d{2})\d+?$/, '$1' ); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function phoneMask ( value ){
	var r = value.replace( /\D/g,'' );
	r = r.replace( /^0/,'' );
	if ( r.length > 10 ) {
		// 11+ digits. Format as 5+4.
		r = r.replace( /^(\d\d)(\d{5})(\d{4}).*/,'($1) $2-$3' );
	}
	else if ( r.length > 5 ) {
		// 6..10 digits. Format as 4+4
		r = r.replace( /^(\d\d)(\d{4})(\d{0,4}).*/,'($1) $2-$3' );
	}
	else if ( r.length > 2 ) {
		// 3..5 digits. Add (0XX..)
		r = r.replace( /^(\d\d)(\d{0,5})/,'($1) $2' );
	}
	else {
		// 0..2 digits. Just add (0XX
		r = r.replace( /^(\d*)/, '($1' );
	}
	return r;
}

export const decimalToCalc = ( value ) => {
	if ( value ) {
		value = value.replace( 'R$', '' );
		return parseFloat( value.replace( /\./gi, '' ).replace( /,/gi, '.' ) );
	}
};

export function decimalMask ( value ){
	// console.log(value)
	var v = value.replace( /\D/g,'' );
	v = ( v/100 ).toFixed( 2 ) + '';
	v = v.replace( '.', ',' );
	v = v.replace( /(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,' );
	v = v.replace( /(\d)(\d{3}),/g, '$1.$2,' );
	value = v;
	return value;
}

export function capitalizeFirstLetter( string ) {
	return string.replace( /\w\S*/g, function( txt ){return txt.charAt( 0 ).toUpperCase() + txt.substr( 1 ).toLowerCase();} );
}

export function mask( value, pattern ) {
	let i = 0;
	const v = value.toString();

	return pattern.replace( /#/g, () => v[i++] || '' );
}

export function cleanNumberCnj( value ) {
	let v = value.replaceAll( '.','' );
	v = v.replace( '-','' );
	v = v.replace( ',', '' );

	return v;
}
