import axios from 'axios';
import { verifyEnvUrl } from '../../util/verifyEnvUrl';


const PROD_WORKFLOW_ACTIONS_URL = 'https://api.acordofechado.com.br/actions';
const DEV_WORKFLOW_ACTIONS_URL = 'https://api.acordofechado.com.br/actions-dev';

const workflowApi = axios.create( {
	baseURL: verifyEnvUrl( PROD_WORKFLOW_ACTIONS_URL, DEV_WORKFLOW_ACTIONS_URL ),
}  );

workflowApi.interceptors.request.use( async config => {
  const token = localStorage.getItem( 'af-token' );
  if ( token ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
} );

export default workflowApi;
