
export const getFormattedDate = ( formattedDate ) => {
	if ( formattedDate ) {
		return formattedDate.split( ',' )[0];
	}
	return 'Data inválida';
};

export const convertDate = ( date ) => {
	if ( typeof date === 'string' ) {
		const newObjectDate = new Date( date );
		const year = newObjectDate.getFullYear();
		const month = String( newObjectDate.getMonth() + 1 ).padStart( 2, '0' );
		const day = String( newObjectDate.getDate() ).padStart( 2, '0' );
		return `${year}-${month}-${day}`;
	}

	if ( typeof date === Date ) {
		const year = date.getFullYear();
		const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
		const day = String( date.getDate() ).padStart( 2, '0' );
		return `${year}-${month}-${day}`;
	}

	return 'Data inválida';
};

export const convertDateDisplay = ( date ) => {
	if ( typeof date === 'string' ) {
		const newObjectDate = new Date( date );
		const year = newObjectDate.getFullYear();
		const month = String( newObjectDate.getMonth() + 1 ).padStart( 2, '0' );
		const day = String( newObjectDate.getDate() ).padStart( 2, '0' );
		return `${day}/${month}/${year}`;
	}

	if ( typeof date === Date ) {
		const year = date.getFullYear();
		const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
		const day = String( date.getDate() ).padStart( 2, '0' );
		return `${day}/${month}/${year}`;
	}

	return 'Data inválida';
};


export const convertDateAndHour = ( date ) => {
	const year = date.getFullYear();
	const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
	const day = String( date.getDate() ).padStart( 2, '0' );

	return `${day}/${month}/${year}`;
};

export const convertDateAndHourMinutes = ( date ) => {
	const year = date.getFullYear();
	const month = String( date.getMonth() + 1 ).padStart( 2, '0' );
	const day = String( date.getDate() ).padStart( 2, '0' );
	const hour = String( date.getHours() ).padStart( 2, '0' );
	const minutes = String( date.getMinutes() ).padStart( 2, '0' );
	const second = String( date.getSeconds() ).padStart( 2, '0' );

	return `${day}/${month}/${year}-H${hour}-${minutes}-${second}`;
};
