import api from './createApi/api';
import { logout } from 'util/utils';
import { BOTS_HISTORY } from 'constants/Links';

const bots = {
  get: async ( { cases: caseId } ) => {
    const base_url = BOTS_HISTORY.replace( ':caseId', caseId );

    try {
      return await api.get( `${base_url}` );
    } catch ( error ) {
      logout( error.response.data.error );

      return error.response;
    }
  }
};

export { bots };
