import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import configureStore, { history } from './store';
import './api/credentials';
import App from './containers/App';
import AppProvider from './context';

export const store = configureStore();
const splitUrl = window.location.href.split( '/app' );

if ( !window.location.href.includes( 'signin' ) && splitUrl.length > 1 ) {
	localStorage.setItem( 'redirect-url', `/app${splitUrl[1]}` );
}

if ( window.location.href.includes( 'https://console.acordofechado.com.br/adv/interest' ) ) {
	const paramInterest = window.location.href.split( 'interest' );
	if( paramInterest.length > 1 ) {
		window.location.href = `https://proposta.acordo-fechado.com/interest${paramInterest[1]}`;
	}
}

if ( window.location.href.includes( 'https://console.acordofechado.com.br/adv/proposal' ) ) {
	const paramProposal = window.location.href.split( 'proposal' );
	if( paramProposal.length > 1 ) {
		window.location.href = `https://proposta.acordo-fechado.com/proposal${paramProposal[1]}`;
	}
}

if ( window.location.href.includes( 'https://console.acordofechado.com.br/minuta' ) ) {
	const paramMinuta = window.location.href.split( 'minuta' );
	if( paramMinuta.length > 1 ) {
		window.location.href = `https://minutas.acordo-fechado.com/minuta${paramMinuta[1]}`;
	}
}

if ( window.location.href.includes( 'detalhe-casos' ) ) {
	window.location.href = 'https://console.acordofechado.com.br/app/casos';
}


const MainApp = () =>
	<Provider store={store}>
		<BrowserRouter>
			<ConnectedRouter history={history}>
				<Switch>
					<AppProvider>
						<Route path="/" component={App}/>
					</AppProvider>
				</Switch>
			</ConnectedRouter>
		</BrowserRouter>
	</Provider>;


export default MainApp;
