import styled, { keyframes } from 'styled-components';

export const skeletonLoadingAnimationLtr = keyframes`
  0% { background-image: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%); }
  25% {background-position: 100% 50% }
  90% { background-position: 0% 10%; }
  100% { background-image: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%); }
`;

export const skeletonLoadingAnimationRtl = keyframes`
  0% { background-image: linear-gradient(270deg, #DBDBDB 50%, rgba(219, 219, 219, 0.05) 100%); }
  20% { background-position: 0%, 0% }
  50% { background-position: 100%, 50% }
  100% { background-image: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 0%); }
`;

export const LoadingSkeletonDinamic = styled.div`
  width: ${props => props.width || '48px'};
  height: ${props => props.height || '48px'};
  border-radius: ${props => props.circle};

  background-size: 200% 200%;

  animation: ${props => props.animation || skeletonLoadingAnimationLtr} ${props => `${props.duration}s` || '1s'} ease-in-out infinite;
`;
