import { store } from '../MainApp';
import { getTenantId } from '../util/isAdmin';
import { npsApi } from './createApi';


export const npsService = {
  register: async ( payload ) => {
    const state = store.getState( state => state );
    return await npsApi.post( '/register', { ...payload, username: state.auth.userLogin, tenant_id: getTenantId() } );
  },
  findLast: async () => {
    const state = store.getState( state => state );
    return await npsApi.post( '/find-last-nps', { username: state.auth.userLogin } );
  }
};
