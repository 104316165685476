import { subclients } from 'api/subclients';
import { useState } from 'react';

export const useWhatsappGroups = () => {
	const [ openWhatsappList, setOpenWhatsappList ] = useState();

	const handleOpenWhatsappList = () => {
		setOpenWhatsappList( !openWhatsappList );
	};

	const fetchWhatsappGroupList = async () => {
		const response = await subclients.getWhatsappGroupList();
		return response.data;
	};
  
	const handleRedirectToGroup = ( link ) => {
		window.open( link, '_blank' );
	};

	return {
		openWhatsappList,
		handleOpenWhatsappList,
		fetchWhatsappGroupList,
		handleRedirectToGroup
	};
};