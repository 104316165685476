import axios from 'axios';

const authenticationApi = axios.create( {
	// baseURL: 'http://localhost:8080'
	baseURL: 'https://api.acordofechado.com.br/authentication'
} );

authenticationApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default authenticationApi;
