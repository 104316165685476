import axios from 'axios';
import { verifyEnvUrl } from 'util/verifyEnvUrl';

const prdURL = 'https://api.acordofechado.com.br/apps-api';
const devURL = 'https://api.acordofechado.com.br/apps-api';
const appsApi = axios.create( {
	baseURL: verifyEnvUrl( prdURL,devURL )
} );

appsApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default appsApi;
