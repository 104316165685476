import { useFetchMenu } from 'containers/SideNav/hooks/useFetchMenu';
import React from 'react';
import { useQuery } from 'react-query';

const SideNavMenuContext = React.createContext( {} );

export const SideNavMenuProvider = ( { children } ) => {
	const [ tenantId, setTenantId ] = React.useState( null );

	const { fetchMenu } = useFetchMenu();
	const { data, isLoading } = useQuery( [ 'list-menu', tenantId ], async () =>
		await fetchMenu( ) );

	return (
		<SideNavMenuContext.Provider value={{ menu: data, isLoading, setTenantId }}>
			{children}
		</SideNavMenuContext.Provider>
	);
};

export function useSideNavMenu() {
	const context = React.useContext( SideNavMenuContext );

	if ( !context ) {
		throw new Error( 'SideNavMenuContext must be used within a SideNavMenuProvider' );
	}

	return context;
}
