const INITIAL_NOTIFY_ALERT = {
  status: false,
  text: ''
}

const notifyAlert = (state = INITIAL_NOTIFY_ALERT, action) => {
  switch(action.type) {
    case "HANDLE_NOTIFY_ALERT_REDUCER": {
      return {...state, ...action.payload}
    }
    default:
    return state;
  }
}

export default notifyAlert
