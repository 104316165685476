import React, { useState, useEffect, useRef } from 'react';
import Nprogress from 'nprogress';
import ReactPlaceholder from 'react-placeholder';
import 'nprogress/nprogress.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import CircularProgress from '../components/CircularProgress/index';

export default function asyncComponent( importComponent ) {
	const AsyncFunc = ( props ) => {
		const [ Component, setComponent ] = useState( null );
		const mounted = useRef( true );

		useEffect( () => {
			Nprogress.start();

			const loadComponent = async () => {
				const { default: Component } = await importComponent();
				Nprogress.done();
				if ( mounted.current ) {
					setComponent( () => Component );
				}
			};

			loadComponent();

			return () => {
				mounted.current = false;
			};
		}, [] );

		const PlaceholderComponent = (
			<div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
				<CircularProgress />
			</div>
		);

		return (
			<ReactPlaceholder type="text" rows={7} ready={Component !== null}>
				{Component ? <Component {...props} /> : PlaceholderComponent}
			</ReactPlaceholder>
		);
	};

	return AsyncFunc;
}
