import { Button } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

export function useRenderPaths(  ) {

	const renderPath = ( { link, icon, title } ) => {
		return (
			<li className="menu no-arrow" key={title}>
				<NavLink to={link ?? '#'}>
					<i className={icon} />
					<span className="nav-text">{title}</span>
				</NavLink>
			</li>
		);
	};

	const renderSubPath = ( { subPath, icon, title } ) => {
		return (
			<li className="menu collapse-box" key={title}>
				<Button>
					<i className={icon} />
					<span className="nav-text">{title}</span>
				</Button>

				<ul className="sub-menu">
					{ subPath.map( ( { label: title, icon, url: link } ) => {
						return ( <li key={title}>
							<NavLink to={link}>
								<i className={icon} />
								<span className="nav-text">{title}</span>
							</NavLink>
						</li> );
					} ) }
				</ul>
			</li>
		);
	};

	return {
		renderPath,
		renderSubPath
	};
}
