import styled from 'styled-components';
import { SwipeableDrawer, makeStyles } from '@material-ui/core';
import { alpha } from '@mui/material';

export const Drawer = styled( SwipeableDrawer )`
  .MuiPaper-root.MuiDrawer-paper{
    width: 30%;
  }
`;

export const HeaderDrawer = styled.div`
  border-bottom: 1px solid #EAEAEA;
  h1{
    margin-bottom: 0;
  }
`;
export const BodyDrawer = styled.div`
  p{
    color: #4A4A4A;
  }
`;

export const FooterDrawer = styled.div`
  border-top: 1px solid #EAEAEA;
  position: absolute;
  bottom: 0;
  p{
    color: #4A4A4A;
    a{
      color: #3281AA;
    }
  }
`;


export const useStyles = makeStyles( ( theme ) => ( {
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing( 2 ),
	},
	title: {
		flexGrow                       : 1,
		display                        : 'none',
		[theme.breakpoints.up( 'sm' )] : {
			display: 'block',
		},
	},
	search: {
		position        : 'relative',
		borderRadius    : theme.shape.borderRadius,
		backgroundColor : alpha( theme.palette.common.white, 0.15 ),
		'&:hover'       : {
			backgroundColor: alpha( theme.palette.common.white, 0.25 ),
		},
		marginLeft   : 0,
		paddingRight : '8px',
		width        : '100%',
	},
	searchIcon: {
		padding        : theme.spacing( 0, 2 ),
		height         : '100%',
		position       : 'absolute',
		pointerEvents  : 'none',
		color          : '#626263',
		display        : 'flex',
		alignItems     : 'center',
		justifyContent : 'center',
		zIndex         : 9999
	},
	inputRoot: {
		backgroundColor : '#fff',
		paddingLeft     : '50px',
		borderRadius    : '8px',
		width           : 'inherit',
	},
	inputInput: {
		padding    : theme.spacing( 1, 1, 1, 0 ),
		transition : theme.transitions.create( 'width' ),
		width      : '100%',
	},
	esc: {
		color        : '#626263',
		fontSize     : '12px',
		padding      : '2px 8px',
		borderRadius : '4px',
		border       : '1px solid #E1EAEF',
		boxShadow    : 'rgba( 0, 0, 0, 0.15 ) 2px 2px 2px 0px',
	}
} ) );
