import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ModalBody, Overlay } from './styles';

function Modal( { visible, onClose, titleHeader, children, hidden } ) {

  if ( !visible ) {
    return null;
  }

  return (
    <Overlay >
      <ModalBody>
        <header>
          <strong>{titleHeader}</strong>
          <button type='button' onClick={onClose} hidden={hidden}>
            <CloseIcon fontSize='small' />
          </button>
        </header>
        {children}
      </ModalBody>
    </Overlay>
  );
}

export default Modal;
