import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import LoadDefault from '../components/LoadDefault';

const { createContext } = require( 'react' );

const LoadingContext = createContext( {} );

export const LoadingProvider = ( { children } ) => {
	const { progress } = useSelector( state => state.settings );

	return (
		<LoadingContext.Provider value={{}}>
			{children}
			<LoadDefault message={''} show={progress.percent} />
		</LoadingContext.Provider>
	);
};

export function useLoading() {
	const context = useContext( LoadingContext );

	if ( !context ) {
		throw new Error( 'useToast must be used within a LoadingProvider' );
	}

	return context;
}
