import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  /*CLIENT_REGISTER,
  CLIENT_EDIT,*/
  CLIENT_FIND,
  COMPANY_DATA
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  company: []
}

export default function clients (state = INIT_STATE, action) {
  switch(action.type) {
    case CLIENT_FIND: {
      return {cliente : 'teste'}
    }
    case COMPANY_DATA: {
      return {
        ...state,
        company: action.payload
      }
    }
    case SHOW_MESSAGE: {
      return {
          ...state,
          alertMessage: action.payload,
          showMessage: true,
          loader: true
      }
    }
    case HIDE_MESSAGE: {
      return {
          ...state,
          alertMessage: '',
          showMessage: false,
          loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
          ...state,
          loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
          ...state,
          loader: false
      }
    }
    default: 
      return state;
  }
}