var messageContainer = {
  message : '',
  show    : false,
  success : true,
};
var loading  = false;

module.exports = {
  setLoading: ( show ) => {
    loading = show;
  },
  sendMessage: ( text, success ) => {
    if( !messageContainer.show ) {
      loading = false;
      messageContainer.message = text;
      messageContainer.success = success;
      messageContainer.show = true;
      setTimeout( () => {
        messageContainer.show = false;
      }, 3000 );
    }
  },
  messageContainer,
  loading,
};
