import { useState } from 'react';

export const useHeaderActions = () => {

	const [ state, setState ] = useState( {
		anchorEl          : undefined,
		searchBox         : false,
		searchText        : '',
		mailNotification  : false,
		userInfo          : false,
		langSwitcher      : false,
		appNotification   : false,
		openDrawer        : false,
		isOpenVideos      : false,
		openHelpCenter    : false,
		qtdTasks          : 0,
		qtdContraProposta : 0,
		qtdNotification   : 0,
		openMenuUser      : false,
		notifications     : [],
	} );

	const handlers = {
		setAnchorEl         : ( value ) => setState( { ...state, anchorEl: value } ),
		setSearchBox        : ( value ) => setState( { ...state, searchBox: value } ),
		setSearchText       : ( value ) => setState( { ...state, searchText: value } ),
		setMailNotification : ( value ) =>
			setState( { ...state, mailNotification: value } ),
		setUserInfo     : ( value ) => setState( { ...state, userInfo: value } ),
		setLangSwitcher : ( value ) =>
			setState( { ...state, langSwitcher: value, anchorEl: value } ),
		setAppNotification: ( value ) =>
			setState( { ...state, appNotification: value } ),
		setOpenDrawer     : ( value ) => setState( { ...state, openDrawer: value } ),
		setIsOpenVideos   : ( value ) => setState( { ...state, isOpenVideos: value } ),
		setOpenHelpCenter : ( value ) =>
			setState( { ...state, openHelpCenter: value } ),
		setOpenMenuUser: ( value ) => setState( { ...state, openMenuUser: value } ),
	};

	return {
		state,
		handlers,
	};
};
