import React from 'react';
import Modal from 'components/ModalVideos';
// import { Container } from './styles';

export function YoutubeMovies( { open, closeVideos } ) {
	if ( !open ) {
		return <></>;
	}
	return (
		<Modal
			closeVideos={closeVideos}
		>
			<iframe
				style={{ width: '100%', height: '500px' }}
				src="https://www.youtube-nocookie.com/embed?&listType=playlist&list=PLmcpz8jabXttNor19vj1R15jNOmysJ1EJ"
				title="Ajuda Acordo Fechado"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen></iframe>
		</Modal>
	);
}
