import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { abstractDataUser } from 'util/isAdmin';

const Error403 = ( props ) => {
	const { default_url } = abstractDataUser();

	return (
		<div className="app-wrapper page-error-container animated slideInUpTiny animation-duration-3">
			<div className="page-error-content">
				<div className="error-code mb-4 animated zoomInDown">403</div>
				<h2 className="text-center fw-regular title bounceIn animation-delay-10 animated">
					<IntlMessages id="extraPages.403Msg"/>
					<p>para acessar a página solicitada:</p>
					<span>{props.currentPath}</span>
				</h2>
				<p className="text-center zoomIn animation-delay-20 animated">
					<Link className="btn btn-primary" to={default_url}><IntlMessages id="extraPages.goHome"/></Link>
				</p>
			</div>
		</div>
	);
};

export default Error403;
