import React from 'react';
import { LoadingDisplayContainer } from './styles';

const LoadingDisplay = ( { rightText } ) => {
  return(
    <LoadingDisplayContainer>
      <div className='loader'></div>
      <span>{rightText}</span>
    </LoadingDisplayContainer>
  );
};

export default LoadingDisplay;
