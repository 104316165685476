import styled from 'styled-components';

export const NotificationListSkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;

  margin-bottom: 32px;

  .skeleton-header {
    display: flex;
    flex-direction: row;
    gap: 32px;

    width: 100%;
  }
`;