import React from 'react';
import { NotificationCardSkeletonContainer } from './styles';
import Skeleton from 'newComponents/Skeleton';

const NotificationCardSkeleton = () => {
  return (
    <NotificationCardSkeletonContainer>
      <div className='icon-section'>
        <Skeleton width='35px' height='35px' radius='50%' />
      </div>
      <div className='info-section'>
        <Skeleton width='80%' height='12px' radius='8px' />
        <Skeleton width='40%' height='10px' radius='8px' />
      </div>
      <div className='action-section'>
        <Skeleton width='18px' height='18px' radius='50%' />
      </div>
    </NotificationCardSkeletonContainer>
  );
};

export default NotificationCardSkeleton;