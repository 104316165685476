import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';

function LoadDefault( { message, show } ) {
  return (
    <Backdrop
      style={{ zIndex: 9999, color: '#fff', flexDirection: 'column' }}
      open={show}
    >
      <CircularProgress color="inherit" />
      <p>{message}</p>
    </Backdrop>
  );
}

export default LoadDefault;
