import axios from 'axios';
import { verifyEnvUrl } from '../../util/verifyEnvUrl';

const prodUrl = 'https://api.acordofechado.com.br/rest-api';
const devUrl = 'https://api.acordofechado.com.br/rest-api-dev';

const api = axios.create( {
	// baseURL: 'http://localhost:3001'
	// baseURL: 'https://api.acordofechado.com.br/rest-api'
	baseURL: verifyEnvUrl( prodUrl, devUrl )
} );

api.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );


export default api;
