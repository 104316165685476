// import axios from "axios";
import { api } from './createApi';
import {
	CLIENTS_EDIT,
	CLIENTS_FIND,
	CLIENTS_REGISTER,
	CLIENTS_REMOVE,
	SUB_CLIENTS_EDIT,
	SUB_CLIENTS_FIND,
	SUB_CLIENTS_REGISTER,
	GROUPS_FIND,
	GROUP_FIND,
	GROUPS_REGISTER,
	// GROUPS_REMOVE,
	GROUPS_EDIT,
	CLIENTS_FIND_ALL,
	CLIENTS_OLD
} from 'constants/Links';
import { setLoading, sendMessage } from 'actions/MessageHandler';
import { logout } from 'util/utils';
import { progressActions } from 'actions/Setting';
import { store } from '../MainApp';
import { clientsApi } from './createApi';

var isRegistering = false;
var isEditing = false;

const clients = {
	remove: async function( payload ) {
		try {
			return await api.delete( CLIENTS_REMOVE, { data: { id: payload } } );
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response.data.error, false );
		}
	},

	find: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.get(
				CLIENTS_FIND + ( payload ? '?id=' + payload.id : '' )
			);
			setLoading( false );
			// sendMessage('Sucesso', true);
			return response.data;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response, false );
		}
	},

	findOLD: async function( payload, signal ) {
		try {
			setLoading( true );
			const response = await api.get(
				CLIENTS_OLD + ( payload ? '?id=' + payload.id : '' ), { signal }
			);
			setLoading( false );
			return response.data;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response, false );
		}
	},

	findAll: async function( payload ) {
		try {
			const response = await api.get( `${CLIENTS_FIND_ALL}${payload || ''}` )
				.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
			return response.data;
		} catch ( error ) {
			logout( error.response.data.error );
			console.log( error.response );
			sendMessage( error.response, false );
		}
	},

	findPaginate: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.get(
				`${CLIENTS_FIND}${payload || ''}`
			);
			setLoading( false );
			// sendMessage('Sucesso', true);
			return response.data;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response, false );
		}
	},
	findOne: async ( clientId ) => {
		return await clientsApi.get( `/client/${clientId}` )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	newRegister: async ( payload ) => {
		return await clientsApi.post( '/client/register', payload )
			.finally( () => store.dispatch( progressActions( { percent: false, message: '' } ) ) );
	},
	register: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.post( CLIENTS_REGISTER, payload );
			sendMessage( 'Sucesso', true );
			return response;
		} catch ( err ) {
			logout( err.response.data.error );
			sendMessage( err.response.data.error, false );
			return err.response;
		}
	},

	edit: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.put(
				CLIENTS_EDIT.replace( ':id', payload.id ),
				payload
			);
			sendMessage( 'Sucesso', true );
			return response;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response.data.error, false );
		}
	},
	getClientsPages: async ( { pageUrl } ) => {
		try {
			const response = await clientsApi.get( pageUrl );
			return response;
		} catch ( error ) {
			return error;
		}
	},
	getSingleClient: async ( id ) => {
		try {
			const response = await clientsApi.get( `/client?id=${id}` );
			return response;
		} catch ( error ) {
			return error;
		}
	},
	postNewClient: async ( payload ) => {
		const response = await clientsApi.post( '/client/register', payload );
		return response;
	},
	editClient: async ( id, payload ) => {
		try {
			const response = await clientsApi.put( `/client/${id}/edit`, payload );
			return response;
		} catch ( error ) {
			return error;
		}
	},
	toggleClientStatus: async ( payload ) => {
		try {
			const response = await clientsApi.patch( '/client/toggle-status', payload );
			return response;
		} catch ( error ) {
			return error;
		}
	}
};

const sub_clients = {
	find: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.get(
				SUB_CLIENTS_FIND + ( payload ? '?id=' + payload.id : '' )
			);
			setLoading( false );
			// sendMessage('Sucesso', true);
			return response.data;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response, false );
		}
	},
	register: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.post( SUB_CLIENTS_REGISTER, payload );
			sendMessage( 'Sucesso', true );
			return response;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response.data.error, false );
		}
	},
	edit: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.put(
				SUB_CLIENTS_EDIT.replace( ':id', payload.id ),
				payload
			);
			sendMessage( 'Sucesso', true );
			return response;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response.data.error, false );
		}
	},
};

const groups = {
	// remove: async function( payload ) {
	//   try {
	//     setLoading( true );
	//     const response = await api.delete( GROUPS_REMOVE, payload );
	//     sendMessage( 'Sucesso', true );
	//     return response;
	//   } catch ( err ) {
	//     logout( err.response.data.error );
	//     console.log( err.response );
	//     sendMessage( err.response.data.error, false );
	//   }
	// },
	findId: async function( groupId ) {
		try {
			const replaceURL = GROUP_FIND.replace( ':id', groupId );
			const response = await api.get( replaceURL );
			return response;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
		}
	},
	find: async function( ) {
		try {
			setLoading( true );
			const response = await api.get( GROUPS_FIND );
			setLoading( false );
			// sendMessage('Sucesso', true);
			return response;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response.data.error, false );
		}
	},
	register: async function( payload ) {
		try {
			return await api.post( GROUPS_REGISTER, payload )
				.then( response => response )
				.finally( () => {
					store.dispatch( progressActions( { percent: false, message: '' } ) );
				} )
				.catch( error => {
					store.dispatch( { type: 'MODAL_LOTE', payload: false } );
					store.dispatch( {
						type    : 'SHOW_MODAL_MESSAGE',
						payload : {
							show  : true,
							from  : '',
							title : '',
							message:
                error.response.data
						}
					} );
				} );

		} catch ( err ) {
			console.log( err.response.data );
			logout( err.response.data.error );
		}
	},
	edit: async function( payload ) {
		try {
			setLoading( true );
			const response = await api.put(
				GROUPS_EDIT.replace( ':id', payload.id ),
				payload
			);
			sendMessage( 'Sucesso', true );
			return response;
		} catch ( err ) {
			logout( err.response.data.error );
			console.log( err.response );
			sendMessage( err.response.data.error, false );
		}
	},
};

export { clients, sub_clients, groups, isEditing, isRegistering };
