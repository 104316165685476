import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SidenavContent from './SidenavContent';
import { Link } from 'react-router-dom';
import logoSite from 'assets/images/logo_site_menor_-1.png';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';
import { Drawer } from '@mui/material';
import { abstractDataUser } from 'util/isAdmin';

export function SideNav () {
	const { navCollapsed } = useSelector( state => state.settings );
	const { default_url } = abstractDataUser();
	const dispatch = useDispatch();

	const onToggleCollapsedNav = () => {
		dispatch( toggleCollapsedNav( !navCollapsed ) );
	};

	React.useEffect( () => {
		window.addEventListener( 'resize', () => {
			dispatch( updateWindowWidth( window.innerWidth ) );
		} );
	}, [ dispatch ] );

	return (
		<div className="app-sidebar d-none">
			<Drawer
				className="app-sidebar-content"
				variant={'temporary'}
				open={!!navCollapsed}
				onClose={onToggleCollapsedNav}
				classes={{
					paper: 'side-nav',
				}}
			>
				<div className="user-profile d-flex flex-row align-items-center">
					<Link className="app-logo mr-2 d-none d-sm-block" to={default_url ?? '#'}>
						<img src={logoSite} alt="AcordoFechado" title="Acordo Fechado" />
					</Link>
				</div>
				<SidenavContent />
			</Drawer>
		</div>
	);
}
