
export const REGISTER_CASES_HEADERS = [
	'ERROS',
	'SUB_CLIENTE_NOME',
	'PROCESSO_PASTA_CLIENTE',
	'PARTES_AUTOR_NOME',
	'PARTES_AUTOR_CPF',
	'PARTES_AUTOR_TELEFONE',
	'PARTES_ADVOGADO_NOME',
	'PARTES_ADVOGADO_EMAIL',
	'PARTES_ADVOGADO_TELEFONE',
	'PARTES_ADVOGADO_OAB',
	'PARTES_OAB_UF',
	'PROCESSO_NUMERO',
	'PROCESSO_ESTADO',
	'PROCESSO_COMARCA',
	'PROCESSO_VARA',
	'PROCESSO_OBJETO',
	'PROCESSO_VALOR',
	'NEGOCIACAO_PROPOSTAS',
	'NEGOCIACAO_PROPOSTA_ATUAL',
	'NEGOCIACAO_PRAZO_PAGAMENTO',
	'NEGOCIACAO_FORMA_PAGAMENTO',
	'NEGOCIACAO_OBRIGACAO_FAZER',
	'NEGOCIACAO_PRAZO_OBRIGACAO',
	'NEGOCIACAO_DATA_LIMITE',
	'CASO_FASE_PROCESSUAL'
];

export const STORAGE_REGISTER_V2_URL = 'af-storage-register-url';

export const STORAGE_REGISTER_V2_ID = 'af-storage-register-id';
