import { progressActions } from 'actions/Setting';
import { cases as cases_api } from 'api/cases';
import { mountFilter } from 'app/routes/Casos/actions';
import { SET_FILTERS_CASE } from 'constants/ActionTypes';
import { call, put, all, fork, takeEvery, select } from 'redux-saga/effects';


function* fetchCases( payload ) {
	const { cases } = yield select();

	yield put( progressActions( { percent: true, message: '' } ) );

	const paginate = Object.assign( {}, payload.pagination );

	let pages = Object.keys( paginate ).length > 0 ? paginate : cases.pagination;
	delete pages.total;

	try {
		return yield cases_api
			.casesReport( { ...payload, pagination: { ...pages } } )
			.then( response => {
				if ( response.data.error ) {
					throw response.data.error;
				}
				return response.data;
			} );

	} catch ( error ) {
		if ( error instanceof Error ) {
			yield put( { type: 'HANDLE_NOTIFY_ALERT_REDUCER', payload: { status: 'error', text: 'Algo deu errado' } } );
		}
	}
}

function* singleFilterCases( payload ) {
	yield put( { type: SET_FILTERS_CASE, payload: payload.payload } );
	yield getCases( payload );
}

function* getCases( { payload } ) {
	try {
		let responseFilter = {
			...mountFilter( payload ),
			pagination: payload?.pagination
		};

		let responseCases = yield call( fetchCases, responseFilter );
		if ( responseCases ) {
			yield put( { type: 'ADD_CASE', payload: responseCases.data } );

			yield put( {
				type    : 'SET_LIMIT_PAGE',
				payload : {
					...responseCases?.pagination
				}
			} );
		}
		yield put( progressActions( { percent: false, message: '' } ) );
	} catch ( error ) {
		console.warn( '[SAGA].getCases', error );
	}
}

export function* filter() {
	yield takeEvery( 'SET_FILTERS_CASE_SAGA', singleFilterCases );
}

export function* fetchCasesObserver() {
	yield takeEvery( 'FETCH_CASES_SAGA', getCases );
}

export default function* rootSaga() {
	yield all( [ fork( filter ), fork( fetchCasesObserver ) ] );
}
