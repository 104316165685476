import React, { useContext, createContext } from 'react';
import { ModalSearch } from 'newComponents/Header/components/modalSearch';
import { cases } from 'api/cases';

const SearchCaseContext = createContext( {} );

export const SearchCaseProvider = ( { children } ) => {
	const [ checked, setChecked ] = React.useState( false );
	const [ listCases, setCases ] = React.useState( null );
	const [ loading, setLoading ] = React.useState( false );


	const handleKeyPress = React.useCallback( ( event ) => {
		if ( ( event.ctrlKey || event.metaKey )  && event.key === 'b' ) {
			setChecked( ( prev ) => !prev );
		}
		if ( event.key === 'Escape' ) {
			setChecked( false );
		}
	},[] );

	React.useEffect( () => {
		const handleKeyDown = ( event ) => {
			handleKeyPress( event );
		};
		document.addEventListener( 'keydown', handleKeyDown );

		return () => {
			document.removeEventListener( 'keydown', handleKeyDown );
			setChecked( false );
			setCases( null );
		};
	},[ handleKeyPress ] );

	const handleFetchCases = React.useCallback( async ( search ) => {
		if ( !search ) {
			return;
		}
		try {
			setLoading( true );
			const response = await cases.casesReport( {
				search
			} );
			setCases( response.data?.data );
		} catch ( error ) {
			if ( error instanceof Error ) {
				console.log( error.message );
			}
		}finally{
			setLoading( false );
		}
	},[ ] );



	return (
		<SearchCaseContext.Provider value={{ loading, listCases, setCases,handleFetchCases, checked, setChecked }}>
			{children}
			{checked && <ModalSearch />}
		</SearchCaseContext.Provider>
	);
};

export function useSearchCase() {
	const context = useContext( SearchCaseContext );

	if ( !context ) {
		throw new Error( 'useToast must be used within a SearchCaseProvider' );
	}

	return context;
}
