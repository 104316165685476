import React, { useContext, useState } from 'react';

import { createContext } from 'react';

const MinutaEmbedModalContext = createContext( {} );

export const MinutaEmbedModalProvider = ( { children } ) => {
	const [ openMinutaEmbed, setOpenMinutaEmbed ] = useState( false );
	const [ docEmbedUrl, setDocEmbedUrl ] = useState( '' );
	const [ confirmDeleteMinuta, setConfirmDeleteMinuta ] = useState( Function );
	const [ fullscreen, setFullscreen ] = useState( false );
	const [ openDraftTemplateModal, setOpenDraftTemplateModal ] = useState( false );
	const [ selectedDraftUrl, setSelectedDraftUrl ] = useState( '' );
	const [ draftBuildController, setDraftBuildController ] = useState( false );

	return (
		<MinutaEmbedModalContext.Provider value={{
			openMinutaEmbed,
			setOpenMinutaEmbed,
			docEmbedUrl,
			setDocEmbedUrl,
			confirmDeleteMinuta,
			setConfirmDeleteMinuta,
			fullscreen,
			setFullscreen,
			openDraftTemplateModal,
			setOpenDraftTemplateModal,
			selectedDraftUrl,
			setSelectedDraftUrl,
			draftBuildController,
			setDraftBuildController,
		}}>
			{children}
		</MinutaEmbedModalContext.Provider>
	);
};

export function useMinutaEmbedModalContext() {
	const context = useContext( MinutaEmbedModalContext );

	if ( !context ) {
		throw new Error( 'useToast must be used within a MinutaEmbedModalContext' );
	}

	return context;
}
