const INITAL_STATE = {
  historys: []
};

export default function Bots( state = INITAL_STATE, action ) {
  switch ( action.type ) {
  case 'SET_HISTORY': {
    let { payload } = action;
    return { ...state, historys: payload };
  }
  default:
    return state;
  }
}
