import {
  HIDE_MESSAGE,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  CLIENT_REGISTER,
  CLIENT_REMOVE,
  CLIENT_EDIT,
  CLIENT_FIND,
  COMPANY_DATA
} from 'constants/ActionTypes';

export const clientRegister = ( client ) => {
  return {
    type    : CLIENT_REGISTER,
    payload : client
  };
};

export const clienteRemove = ( client ) => {
  return {
    type    : CLIENT_REMOVE,
    payload : client
  };
};

export const clientEdit = ( client ) => {

  return {
    type    : CLIENT_EDIT,
    payload : client
  };
};

export const clientFind = ( client ) => {
  return {
    type    : CLIENT_FIND,
    payload : client
  };
};


export const showAuthMessage = ( message ) => {
  return {
    type    : SHOW_MESSAGE,
    payload : message
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const companyData = () => {
  return {
    type: COMPANY_DATA
  };
};
