import React from 'react';
import ClosedDialer from './ClosedDialer';
import DialerPopup from './DialerPopup';
import { useDialerContext } from '../DialerContext';

const DialerController = ( { caseContacts } ) => {
	const { isOpen, handleClose, handleOpen } = useDialerContext();

	if ( !isOpen ) {
		return (
			<ClosedDialer handleOpen={handleOpen} ></ClosedDialer>
		);
	}

	return (
		<DialerPopup 
			handleClose={handleClose}
			caseContacts={caseContacts}
		/>
	);
};

export default DialerController;