import { notifications } from 'api/notifications';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

export const useNotificationRequests = () => {
	const [filteredNotifications, setFilteredNotifications] = useState( [] );
	const [loadingUpdateNotification, setLoadingUpdateNotification] = useState( false );
	const queryClient = useQueryClient();

	const getAllNotifications = async () => {
		try {
			const response = await notifications.listAllNotifications();
			setFilteredNotifications( response.data.filter( notice => notice.read === false ) );

			return response?.data || [];

		} catch ( error ) {
			console.log( error );
		}
	};

	const handleUpdateNotification = async ( allNotification, notification, setLoading, isLink ) => {
		const payload = {
			read: !notification.read,
		};

		if ( isLink ) {
			payload.read = true;
		}

		let notif = allNotification.find( notice => notice._id === notification._id );
		if ( !notif ) {
			notif = { ...notification, read: !notification.read };
		} else {
			notif.read = !notif.read;
		}

		setFilteredNotifications( allNotification );

		const response = await notifications.updateNotification( payload, notification._id );
		await queryClient.invalidateQueries( ['notifications'] );

		return response.data;
	};

	return {
		getAllNotifications,
		filteredNotifications,
		handleUpdateNotification,
		loadingUpdateNotification,
		setLoadingUpdateNotification,
	};
};
