import notificationsApi from './createApi/notificationsApi';

export const notifications = {
  listAllNotifications: async function () {
    const response = await notificationsApi.get( '/notice' );
    return response;
  },

  updateNotification: async function ( payload, noticeId ) {
    const response = await notificationsApi.put( `/notice/${noticeId}`, payload );
    return response;
  }
};