import React from 'react';
import { CalLBtnText, CallHangupBtn, ClickToCallBtn } from './styles';
import { Call } from '@material-ui/icons';

const CallHandlerBtn = ( { handleCall, disableColor, handleHangup, sessionInProgress } ) => {
	if ( !sessionInProgress ) {
		return (
			<ClickToCallBtn onClick={handleCall} disableColor={disableColor}>
				<Call fontSize='small' />
				<CalLBtnText>Ligar</CalLBtnText>
			</ClickToCallBtn>
		);
	}

	return (
		<CallHangupBtn onClick={handleHangup} >
			<Call fontSize='small' />
			<CalLBtnText>Desligar</CalLBtnText>
		</CallHangupBtn>
	);
};

export default CallHandlerBtn;