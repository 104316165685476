import React from 'react';
import { NpsProvider } from './NpsContext';

const AppProvider = ( { children } ) => {
	return (
		<NpsProvider>
			{children}
		</NpsProvider>
	);
};

export default AppProvider;
