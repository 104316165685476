import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

const delay = time => new Promise( resolve => setTimeout( resolve, time ) );

function* handleNotify ( { payload } ) {
	yield put( { type: 'HANDLE_NOTIFY_ALERT_REDUCER', payload } );
	yield call( delay, 100 );
	yield put( { type: 'HANDLE_NOTIFY_ALERT_REDUCER', payload: { status: false, text: '' } } );
}

export function* notifyAlert() {
	yield takeEvery( 'HANDLE_NOTIFY_ALERT', handleNotify );
}

export default function* rootSaga() {
	yield all( [fork( notifyAlert )] );
}

