import { useHistory } from 'react-router';

export const useClientEnvironmentNav = () => {
	const history = useHistory();
	const handleRedirect = ( pageUrl ) => {
		const actualPageSplitted = window.location.href.split( 'app' )[1];
		const actualPage = `/app${actualPageSplitted}`;
		if ( actualPage === pageUrl ) {
			return;
		}
		return history.push( pageUrl );
	};

	return {
		handleRedirect,
	};
};