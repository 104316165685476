import React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { Help, HelpRounded, YouTube } from '@mui/icons-material';
import { Button, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import * as S from './styles';
import WhatsappCollapseGroupList from 'app/routes/ClientEnvironment/Whatsapp/WhatsappCollapseGroupList';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { ErrorOutline } from '@material-ui/icons';
import { useClientEnvironmentNav } from 'newComponents/Header/hooks/useClientEnvironmentNav';

export function CustomerCenter( { handlers, openHelpCenter } ) {
	const { handleRedirect } = useClientEnvironmentNav();
	const anchorRef = React.useRef( null );
	const classes = S.useStyles();
	return (
		<div className={classes.root}>
			<Button
				className={classes.button}
				ref={anchorRef}
				aria-controls={openHelpCenter ? 'menu-list-customer-center' : undefined}
				aria-haspopup="true"
				onClick={() => handlers.setOpenHelpCenter( !openHelpCenter )}
			>
				<Help fontSize="small" /> Central do cliente
			</Button>
			<Popper  open={openHelpCenter} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
				{( { TransitionProps, placement } ) => (
					<Grow
						{...TransitionProps}
						className={classes.grow}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
						<Paper>
							<ClickAwayListener onClickAway={() => handlers.setOpenHelpCenter( false )}>
								<MenuList className={classes.menuList} id="menu-list-customer-center" autoFocusItem>
									<WhatsappCollapseGroupList />
									<MenuItem onClick={() => window.open( 'https://acordofechado.kampsite.co/', '_blank' )} className={classes.menuItem}> <HelpRounded style={{ color: '#034E79' }} /> Sugestões de melhoria</MenuItem>
									<MenuItem className={classes.menuItem} onClick={() => handleRedirect( '/app/central-cliente?tab=videos' )}>
										<YouTube style={{ color: 'red' }}/>
										Assistir a vídeos tutoriais
									</MenuItem>		
									<MenuItem className={classes.menuItem} onClick={() => handleRedirect( '/app/central-cliente?tab=manual' )}>
										<MenuBookIcon style={{ color: '#1781AA' }}/>
										Manuais Acordo Fechado
									</MenuItem>
									<MenuItem className={classes.menuItem} onClick={() => handleRedirect( '/app/central-cliente?tab=faq' )}>
										<ErrorOutline style={{ color: '#034E79' }} />
										FAQ
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
