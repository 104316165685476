import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( {
	button: {
		backgroundColor : '#fff !important',
		padding         : '6px 8px',
		borderRadius    : '4px',
		color           : '#626263 !important',
		width           : 'inherit',
		textTransform   : 'none !important',
		fontWeight      : '400 !important',
		gap             : '8px'
	}
} );
