
import { EMAIL_SEND, CAPTURE_CONTACTS } from 'constants/Links';
import { logout } from 'util/utils';
import { workflowApi } from './createApi';

const mails = {
	sendMails: async function( payload ) {
		try {
			return await workflowApi.post( EMAIL_SEND, { ...payload } );
		} catch ( error ) {
			logout( error.response.data.error );
			console.error( error.response );
			return error.response;
		}
	}
};

const contacts = {
	capture: async ( casesIds, userEmail ) => {
		return await workflowApi.post( CAPTURE_CONTACTS, { cases: casesIds, userEmail } );
	}
};

export { mails, contacts };
