import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingCenterDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 12px;

  .loading-section__title {
    font-weight: bold;
    font-size: 16px;
    color: #000;
  }

  .loader {
    border: 2px solid transparent;
    border-top: 2px solid #004E7B;
    border-right: 2px solid #004E7B;
    padding: 0px;
    margin: 0px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: ${rotate} 1s linear infinite;
  }
`;