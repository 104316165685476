import styled from 'styled-components';

export const MinimalNotificationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .minimal-notification__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding: 8px 16px;

    border-bottom: 1px solid #DADADA;

    .header-title {
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      line-height: 169.25%;
      font-weight: 500;
    }

    .header-link {
      font-family: Roboto;
      font-size: 12px;
      line-height: 169.25%;
      text-decoration: underline;
      font-style: normal;
      font-weight: 500;
      color: #1781AA;
    }
  }

  .minimal-notification__cards {
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    max-height: 300px;
    padding-right: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;

      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #C4C4C4;
      border-radius: 18px;
    }
  }
`;
