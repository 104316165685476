import React, { useContext, useEffect, useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';

const { createContext } = require( 'react' );

const NotifyAlertContext = createContext( {} );

// success | error | false
const INIT_SHOW_NOTIFY = { status: false, text: '', title: '', timeOut: 5000, action: null };

export const NotifyAlertProvider = ( { children } ) => {
  const [ showNotify, setShowNotify] = useState( INIT_SHOW_NOTIFY );

  let notifyAlert = useSelector( state => state.notifyAlert );
  notifyAlert = showNotify.status ? showNotify : notifyAlert;

  useEffect( () => {
    if (notifyAlert.status) {
      NotificationManager[notifyAlert.status](notifyAlert.text, notifyAlert.title, notifyAlert.timeOut || INIT_SHOW_NOTIFY.timeOut, notifyAlert.action);
      // Limpe o estado após exibir a notificação
      setTimeout(() => {
        setShowNotify(INIT_SHOW_NOTIFY);
      }, 300);
    }
  }, [showNotify, notifyAlert] );

  return (
    <NotifyAlertContext.Provider value={{ showNotify, setShowNotify }}>
      {children}
      <NotificationContainer />
    </NotifyAlertContext.Provider>
  );
};

export function useNotifyAlert() {
  const context = useContext( NotifyAlertContext );

  if ( !context ) {
    throw new Error( 'useToast must be used within a NotifyAlertProvider' );
  }

  return context;
}
