import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( ( theme ) => ( {
	root: {
		display: 'flex',
	},
	button: {
		background    : '#116E99 !important',
		borderRadius  : '40px !important',
		padding       : '8px 16px !important',
		display       : 'flex',
		gap           : '4px',
		color         : `${theme.palette.primary.contrastText} !important` ,
		textTransform : 'none !important',
		fontWeight    : `${400} !important`,
	},
	grow: {
		padding: '0px 16px !important'
	},
	menuList: {
		display       : 'flex',
		flexDirection : 'column',
		gap           : '8px',
	},
	menuItem: {
		gap       : '8px',
		color     : '#626263 !important',
		opacity   : 0.8,
		padding   : '4px 0px !important',
		'&:hover' : {
			backgroundColor: 'transparent !important'
		}
	}
} ) );