import { all, fork, put, takeEvery } from '@redux-saga/core/effects';
import { progressReducer, setErrorAlert } from '../actions';

function* updateModalMessage( { payload } ) {
  const modal = { ...payload };
  yield put( { type: 'SHOW_MODAL_MESSAGE', payload: modal } );
}

export function* handleLoad( { payload } ) {
  yield put( { type: 'SET_DEFAULT_LOAD', payload } );
}

function* getProgress( { payload } ) {
  yield put(
    progressReducer( {
      ...payload
    } )
  );
}

function* setError( { payload } ) {
  yield put(
    setErrorAlert( {
      ...payload
    } )
  );
}

export function* modalMessageObserver() {
  yield takeEvery( 'SHOW_MODAL_MESSAGE_SAGA', updateModalMessage );
}

export function* progressObserver() {
  yield takeEvery( 'HANDLE_PROGRESS', getProgress );
}

export function* defaulLoadObserver() {
  yield takeEvery( 'SET_DEFAULT_LOAD_SAGA', handleLoad );
}

export function* defaultErrorObserver() {
  yield takeEvery( 'SET_ERROR_SAGA', setError );
}

export default function* rootSaga() {
  yield all( [
    fork( modalMessageObserver ),
    fork( defaulLoadObserver ),
    fork( progressObserver ),
    fork( defaultErrorObserver )
  ] );
}
