import React, { createContext, useCallback, useContext, useState } from 'react';
import Nps from '../newComponents/Nps';
import { npsService } from '../api/nps';

const NpsContext = createContext( {} );

export const NpsProvider = ( { children } ) => {
  const [show, setShow] = useState( false );
  const execute = useCallback( () => {
    npsService.findLast().then( ( response ) => {
      if ( response.data.message === 'Pendente' ) {
        setShow( true );
      }
    } );
  }, [] );

  return (
    <NpsContext.Provider value={{ setShow, execute }}>
      {children}
      <Nps show={show} setShow={setShow}/>
    </NpsContext.Provider>
  );
};

export function useNPSContext() {
  const context = useContext( NpsContext );

  if ( !context ) {
    throw new Error( 'useNPSContext must be used within a NpsProvider' );
  }

  return context;
}
