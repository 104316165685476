// var BASE = 'http://34.95.145.126:3001';
var BASE = process.env.REACT_APP_HOST;

export const BASE_URL = BASE;

export const AUTH_SIGN_UP = '/api/auths/signup';
export const AUTH_LOG_OUT = '/api/auths/logout';
export const AUTH_UPDATE = '/api/auths/edit';
export const AUTH_ALL = '/api/auths';

export const CLIENTS_EDIT = '/api/clients/:id/edit';
export const CLIENTS_FIND = '/api/clientsPaginate';
export const CLIENTS_OLD = '/api/clients';
export const CLIENTS_FIND_ALL = '/api/findClients';
export const CLIENTS_REMOVE = '/api/clients/remove';
export const CLIENTS_REGISTER = '/api/clients/register';

export const SUB_CLIENTS_EDIT = '/api/subclients/:id/edit';
export const SUB_CLIENTS_FIND = '/api/subclients';
export const SUB_CLIENTS_REGISTER = '/api/subclients/register';

export const PERSONS_REMOVE = '/api/persons/remove';
export const PERSONS_FIND = '/api/persons';
export const PERSONS_FID_QUERY = '/api/persons/findQuery';
export const PERSONS_REGISTER_OR_UPDATE = '/person/create-or-update';

export const DOCUMENTS_REMOVE = '/api/documents/:id/delete';
export const DOCUMENTS_FIND = '/api/persons/:id/documents';
export const DOCUMENTS_REGISTER = '/api/persons/:id/documents/register';

export const PROCESS_FIND = '/api/processes';
// export const PROCESS_REMOVE = '/api/processes/disable';
export const PROCESS_REGISTER = '/api/processes/register';
export const PROCESS_FID_QUERY = '/api/tools/processParties';
// export const PROCESS_UPDATE = '/api/processes/:id/parties';
export const PARTIES_EDIT = '/api/parties/:party_id';
export const PROCESSES_MONITORING = '/list-processes';

// export const DEALS_FIND = '/api/deals';
// export const DEALS_REMOVE = '/api/deals/disable';
// export const DEALS_REGISTER = '/api/deals/register';
export const DEALS_UPDATE = '/api/deals/updateStatus';
export const DEALS_CONSTANTS = '/api/constants/dealStatus';

export const CASES_FIND = '/api/cases';
// export const CASES_REMOVE = '/api/cases/disable';
// export const CASES_REGISTER = '/api/cases/register';
export const CASES_MASS_EDIT = '/api/tools/massEdit';
export const CASES_ZAPSIGN = '/api/cases/:caseId/zapsign';
export const CASES_SIGNED_DRAFT = '/api/cases/:caseId/signedDraft';

export const BULK_UPLOAD = '/api/register/validate';
export const BULK_REGISTER = '/api/register/bulk';

export const BULK_VALIDATE = '/validate';
export const NEW_BULK_REGISTER = '/register';
export const NEW_BULK_CHECK = '/progress/:id';

// export const BULK_FIND = '/api/register/bulk/';

export const GROUP_FIND = '/api/groups/:id';
export const GROUPS_FIND = '/api/groups';
// export const GROUPS_REMOVE = '/api/groups/disable';
export const GROUPS_REGISTER = '/api/groups/register';
export const GROUPS_EDIT = '/api/groups/:id/edit';

export const TASKS_FIND = '/api/tasks';
export const TASKS_FIND_KANBAN = '/api/tasksKanban';
export const TASKS_UPDATE = '/api/tasks/:id/edit';
export const TASKS_REGISTER = '/api/tasks/create';
export const TASKS_COMPLETED = '/api/tasks/bulkComplete';

export const DOWNLOAD = '/api/register/bulk/errors';

export const DATES_FIND = '/api/dates';

// Workflow
export const EMAIL_SEND = '/workflow/sendMails';
export const CAPTURE_CONTACTS = '/workflow/contacts';

/* Dashboard*/
export const DASHBOARD_CASE_SITUATIONS = '/api/dashboard/caseStatus';
export const NEW_DASHBOARD_CASES = '/dashboard/cases';
export const NEW_DASHBOARD_TASKS = '/dashboard/tasks';
export const NEW_DASHBOARD_CHART = '/dashboard/chart';
export const NEW_DASHBOARD_TICKET = '/dashboard/averageTicket';
export const NEW_DASHBOARD_CASE_STATUS = '/dashboard/casesStatus';
export const NEW_DASHBOARD_TASK_SUCCESS = '/dashboard/taskSuccess';
export const NEW_DASHBOARD_SUCCESS_RESULTS = '/dashboard/successResults';
export const DASHBOARD_COUNTERPROPOSAL_CHART = '/dashboard/counterproposal-chart';

// Minuta
export const MODELO_MINUTA = '/api/tools/draft/:caseId';
export const BUILD_DRAFT = '/api/tools/buildDraft/:externalId';
export const REMOVE_DRAFT = '/api/tools/buildDraft/:caseId';

export const BOTS_HISTORY = '/api/bots/history/:caseId';
export const LOG_MINUTA = '/document/log/:caseId';

//WhatsApp
export const WHATSAPP_SEND = '/message';
export const WHATSAPP_SEND_BATCH = '/batchMessage';
export const WHATSAPP_LOG = '/message/:caseId';
