import axios from 'axios';

const configIntegrationApi = axios.create( {
  baseURL: 'https://api.acordofechado.com.br/config-integration'
} );

configIntegrationApi.interceptors.request.use( async config => {
  const token = localStorage.getItem( 'af-token' );
  if ( token ) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
} );

export default configIntegrationApi;
