import { showSettingsLoader, showModalMessage } from 'actions';
import { contacts } from 'api/workflow';
import { CAPTURE_CONTACTS } from 'constants/ActionTypes';
import { all, fork, takeEvery, put } from 'redux-saga/effects';

function* req_capture_contacts( { payload } ) {
  const caseId = payload;
  yield put( showSettingsLoader( true ) );

  yield put(
    showModalMessage( {
      from    : '',
      show    : false,
      title   : '',
      message : ''
    } )
  );

  try {
    const response = yield contacts.capture( caseId );

    yield put( showSettingsLoader( false ) );

    yield put(
      showModalMessage( {
        from    : 'singleCase',
        show    : true,
        title   : 'Atenção',
        message : response.data.message
      } )
    );
  } catch ( error ) {
    yield put( showSettingsLoader( false ) );
    yield put(
      showModalMessage( {
        from    : 'singleCase',
        show    : true,
        title   : 'Atenção',
        message : `[${error.status}] - ${error.statusText}`
      } )
    );
  }
}

export function* capture_contacts() {
  yield takeEvery( CAPTURE_CONTACTS, req_capture_contacts );
}

export default function* rootSaga() {
  yield all( [fork( capture_contacts )] );
}
