import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const DialerClosedInteractible = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  width: 50px;
  height: 50px;

  background-color: #1781AA;
  color: #FFF;

  position: fixed;
  top: calc(50% - 20%);
  right: 32px;
  transform: translateY(-50%);

  z-index: 9999;

  cursor: pointer;
`;

export const DialerPopupContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 400px;
  height: 500px;

  position: fixed;
  top: calc(50%);
  right: 32px;
  transform: translateY(-50%);

  background-color: #FFF;

  z-index: 9999;

  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
`;

export const DefaultCallActionBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #626263;
  background-color: #ECECEC;

  cursor: pointer;
  
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const ClickToCallBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #fff;
  background-color: ${props => props.disableColor ? '#0CB969' : '#D0D0D0'};
  
  cursor: ${props => props.disableColor ? 'pointer' : 'default'};
  
  width: 65px;
  height: 65px;
  border-radius: 50%;
`;

export const CallHangupBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #fff;
  background-color: #EB5A46;

  cursor: pointer;
  
  width: 65px;
  height: 65px;
  border-radius: 50%;
`;

export const CalLBtnText = styled.span`
  font-weight: 500;
  font-size: 12px;
`;

export const DialerStatusTitle = styled.span`
  color: #7A7A7A;
  font-weight: 400;
  font-size: 12px;
`;

export const DialerConnectionText = styled.span`
  color: #626263;
  font-weight: 400;
  font-size: 10px;
`;

export const DialerNumberDisplay = styled.span`
  color: #919192;
  font-size: 16px;
  font-weight: 400;
`;

export const DialerTimer = styled.span`
  color: #7A7A7A;
  font-size: 24px;
  font-weight: 700;
`;

export const CallActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 18px;
`;

export const GreenSwitch = withStyles( {
	switchBase: {
		color       : '#919192',
		'&$checked' : {
			color: '#0CB969',
		},
		'&$checked + $track': {
			backgroundColor: '#0CB969',
		},
	},
	checked : {},
	track   : {},
} )( Switch );

export const KeyboardKey = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 40px;

  color: #343435;
  font-weight: 700;
  font-size: 16px;
  background-color: #FFF;

  cursor: pointer;

  border-color: #ECECEC;
  border-radius: 5px;
  border-width: 0.5px;
  box-shadow: 0px 2px 1px 0px #DBDBDB;
`;