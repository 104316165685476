import React from 'react';
import { LoadingSkeletonDinamic } from './styles';
import { useSkeletonAnimation } from './hooks';

const Skeleton = ( { children, width, height, radius, animation, duration = 1 } ) => {
	const { hookStates } = useSkeletonAnimation();
	const { alternateAnimation } = hookStates;

	// React.useEffect( () => {
	//   let isMounted = true;

	//   if ( !animation ) {
	//     const timeoutId = setTimeout(()=> {
	//       if(isMounted)  {
	//         hookHandlers.handleAnimateSkeleton( parseInt( ( duration * 1000 ) - 50 ) );
	//       }
	//     }, duration * 1000 - 50);

	//     return () => {
	//       isMounted = false;
	//       clearTimeout(timeoutId);
	//     }
	//   }
	// }, [alternateAnimation] ); //eslint-disable-line

	return (
		<LoadingSkeletonDinamic
			width={width}
			height={height}
			circle={radius ? radius : 0}
			animation={animation ? animation : alternateAnimation}
			duration={duration}
		>
			{children}
		</LoadingSkeletonDinamic>
	);
};

export default Skeleton;
