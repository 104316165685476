import styled, { css } from 'styled-components';
import { devices } from '../../util/device';


export const buttonPrimary = css`
  background-color: #1781AA;
  color: #FFFFFF;
  border: 1px solid #1781AA;
  border: none;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
`;

export const buttonSecondary = css`
  background-color: transparent;
  color: #808080;
  border-color: #808080;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;

const buttonTertiary = css`
  color: #1781AA;
  background-color: #fff;
  border: solid 1px #1781AA;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const buttonCancel = css`
  color: #fff;
  background-color: #EB5A46;
  border: 1px solid #EB5A46;
  gap: 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

const buttonGreen = css`
    color: #fff;
    background-color: #6BBB1E;
    border: 1px solid #6BBB1E;
    gap: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`;
const buttonDenger = css`
    color: #fff;
    background-color: #f56565;
    border: 1px solid #f56565;
    gap: 8px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`;

export const chooseVariant = {
	'Primary'   : buttonPrimary,
	'Secondary' : buttonSecondary,
	'Default'   : buttonPrimary,
	'Tertiary'  : buttonTertiary,
	'Green'     : buttonGreen,
	'Denger'    : buttonDenger,
	'Red'       : buttonCancel,
};

export const Container = styled.button`
  border-radius: 2px;
  padding: 4px 16px;
  text-align: center;
  justify-content: center;

  &:disabled {
    cursor: no-drop;
    color: #B9B9B9;
    background-color: transparent;
    border: 1px solid #B9B9B9;

    &:hover {
      color: #B9B9B9;
      border: 1px solid #B9B9B9;
      background-color: transparent;
      opacity: inherit;

      svg {
        color: #B9B9B9 !important;
      }
    }
  }

  cursor: pointer;
  line-height: 1.75rem;


  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  min-width: 100%;
  @media ${devices.mobileL} {
    min-width: 64px;
  }

  ${( props ) => chooseVariant[props.variant] ?? chooseVariant['Default']}

`;
