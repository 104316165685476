import { Box } from '@material-ui/core';
import React from 'react';
import { KeyboardKey } from './styles';
import { useDialerContext } from '../DialerContext';
import { Backspace } from '@material-ui/icons';

const Keyboard = () => {
	const { handleChangePhoneNumber } = useDialerContext();
	const BACKSPACE = 'backspace';
	const KEYLIST = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#' ];
	return (
		<Box display={'flex'} flexDirection={'column'} width={'100%'} style={{ gap: '4px' }} >
			<Box display={'flex'} flexDirection={'row'} justifyContent={'end'} width={'100%'} style={{ paddingRight: '16px' }}>
				<Backspace 
					style={{ color: '#919192', cursor: 'pointer' }} 
					fontSize='small' 
					onClick={() => handleChangePhoneNumber( BACKSPACE )}
				/>
			</Box>
			<Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'center'} style={{ gap: '8px', backgroundColor: '#ECECEC', padding: '16px' }}>
				{KEYLIST.map( value => (
					<KeyboardKey key={value} onClick={() => handleChangePhoneNumber( value )} >{value}</KeyboardKey>
				) )}
			</Box>
		</Box>
	);
};

export default Keyboard;