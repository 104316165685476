import React from 'react';

import { useStyles } from './styles';
import SearchIcon from '@material-ui/icons/Search';
import { useSearchCase } from 'context/SearchCase';
import Button from '@mui/material/Button';

function SearchCase() {
	const classes = useStyles();
	const { setChecked } = useSearchCase();

	return (
		<Button
			className={classes.button}
			onClick={ () => setChecked( ( prev ) => !prev )}>
			<SearchIcon />Id do caso, Autor, Nº do processo...
		</Button>
	);
}

export default SearchCase;
