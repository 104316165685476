import appLocaleData from 'react-intl/locale-data/pt';
import enMessages from '../locales/pt_BR.json';

const PtLang = {
	messages: {
		...enMessages
	},
	locale : 'pt-BR',
	data   : appLocaleData
};
export default PtLang;
