import {
	CHANGE_DIRECTION,
	CHANGE_NAVIGATION_STYLE,
	FIXED_DRAWER,
	HORIZONTAL_MENU_POSITION,
	INSIDE_THE_HEADER,
	SWITCH_LANGUAGE,
	TOGGLE_COLLAPSED_NAV,
	VERTICAL_NAVIGATION,
	WINDOW_WIDTH
} from 'constants/ActionTypes';

const rltLocale = [ 'ar' ];

const initialSettings = {
	navCollapsed          : false,
	drawerType            : FIXED_DRAWER,
	width                 : window.innerWidth,
	isDirectionRTL        : false,
	navigationStyle       : VERTICAL_NAVIGATION,
	horizontalNavPosition : INSIDE_THE_HEADER,
	maintenance           : false,
	progress              : {
		percent : false,
		message : ''
	},
	locale: {
		languageId : 'portuguese',
		locale     : 'br',
		name       : 'Portuguese',
		icon       : 'us'
	},
	btDetailCases: {
		save_next : 'Salvar e avançar para próximo caso',
		save      : 'Salvar'
	},
	detailTab      : 'case',
	nextCase       : false,
	defaultLoading : false,
	loading        : {
		task: false
	},
	error: {
		message : '',
		show    : false
	},
	message: {
		from    : '',
		show    : false,
		title   : '',
		message : ''
	}
};

const settings = ( state = initialSettings, action ) => {
	switch ( action.type ) {
	case '@@router/LOCATION_CHANGE':
		return { ...state, navCollapsed: false };
	case TOGGLE_COLLAPSED_NAV:
		return { ...state, navCollapsed: action.isNavCollapsed };
	case WINDOW_WIDTH:
		return { ...state, width: action.width };
	case SWITCH_LANGUAGE:
		return {
			...state,
			locale         : action.payload,
			isDirectionRTL : rltLocale.includes( action.payload.locale )
		};

	case CHANGE_DIRECTION:
		return { ...state, isDirectionRTL: !state.isDirectionRTL };

	case CHANGE_NAVIGATION_STYLE:
		return { ...state, navigationStyle: action.payload };

	case HORIZONTAL_MENU_POSITION:
		return { ...state, horizontalNavPosition: action.payload };

	case 'CHANGE_TEXT_BT_SAVE_CASE': {
		const { payload } = action;
		return {
			...state,
			btDetailCases: { ...state.btDetailCases, ...payload }
		};
	}

	case 'CHANGE_TAB_DETAIL': {
		return { ...state, detailTab: action.payload };
	}

	case 'NEXT_CASE': {
		return { ...state, nextCase: action.payload };
	}

	case 'SET_LOAD': {
		return { ...state, loading: { ...action.payload } };
	}

	case 'SET_DEFAULT_LOAD': {
		return { ...state, defaultLoading: action.payload };
	}

	case 'SHOW_MODAL_MESSAGE': {
		return {
			...state,
			message: {
				...state.message,
				...action.payload
			}
		};
	}

	case 'SET_PROGRESS': {
		return { ...state, progress: { ...action.payload } };
	}

	case 'SET_ERROR': {
		return { ...state, error: { ...action.payload } };
	}

	default:
		return state;
	}
};

export default settings;
