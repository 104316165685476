import axios from 'axios';

export const bulkCaseApi = axios.create( {
	// baseURL: 'https://api.acordofechado.com.br/bulk-case-register'
	baseURL: 'https://bulk-case-register-api-c2bcf3j4ta-rj.a.run.app'
} );

bulkCaseApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default bulkCaseApi;
