import React from 'react';
import { MinimalNotificationListContainer } from './styles';
import { Link } from '@material-ui/core';
import { useNotificationRequests } from '../../hooks/notificationRequests';
import NotificationCard from '../NotificationCard';
import { useQuery } from 'react-query';
import NotificationListSkeleton from 'newComponents/DefaultSkeletons/NotificationListSkeleton';

function MinimalNotificationList () {
	const { getAllNotifications, loadingUpdateNotification, setLoadingUpdateNotification } = useNotificationRequests();

	const { data, isLoading } = useQuery( {
		queryKey  : ['notifications'],
		queryFn   : getAllNotifications,
		staleTime : 30000
	} );

	if ( isLoading || loadingUpdateNotification ) {
		return (
			<MinimalNotificationListContainer>
				<div className='minimal-notification__header'>
					<span className='header-title'>Notificações</span>
					<span className='header-link'>
						<Link color='inherit' href={`${window.location.href.split( 'app' )[0]}app/notificacoes`}>Ver tudo</Link>
					</span>
				</div>
				<div className='minimal-notification__cards'>
					<NotificationListSkeleton />
				</div>
			</MinimalNotificationListContainer>
		);
	}

	return (
		<MinimalNotificationListContainer>
			<div className='minimal-notification__header'>
				<span className='header-title'>Notificações</span>
				<span className='header-link'>
					<Link color='inherit' href={`${window.location.href.split( 'app' )[0]}app/notificacoes`}>Ver tudo</Link>
				</span>
			</div>
			<div className='minimal-notification__cards'>
				{
					data?.length > 0 ?
						data.map( notification => (
							<NotificationCard
								allNotification={data}
								key={notification._id}
								notification={notification}
								isModal
								setLoading={setLoadingUpdateNotification}
							/>
						) )
						:
						<span style={{ padding: '8px 16px' }}>Nenhuma notificação foi encontrada.</span>
				}
			</div>
		</MinimalNotificationListContainer>
	);
}

export default MinimalNotificationList;
