import subclientsApi from './createApi/subclientsApi';

export const subclients = {
	getSubclientsPaginate: async function( pageUrl ) {
		try {
			const response = await subclientsApi.get( pageUrl );
			return response;
		} catch ( error ) {
			return error.response;
		}
	},
	getSubclientDetails: async function( subclientId ) {
		try {
			const response = await subclientsApi.get( `/sub-client?id=${subclientId}` );
			return response;
		} catch ( error ) {
			return error.response;
		}
	},
	postNewSubclient: async function( payload ) {
		const response = await subclientsApi.post( '/sub-client', payload );
		return response;
	},
	editSubclient: async function( payload, subclientId ) {
		const response = await subclientsApi.put( `/sub-client/${subclientId}`, payload );
		return response;
	},
	updateSubclientStatus: async function( payload ) {
		try {
			const response = await subclientsApi.patch( '/sub-client/toggle-status', payload );
			return response;
		} catch ( error ) {
			return error.response;
		}
	},
	getWhatsappGroupList: async function() {
		const response = await subclientsApi.get( '/whatsapp-group-list' );
		return response;
	}
};
