export const convertDateAndHour = ( unformattedDate ) => {
  if ( unformattedDate ) {
    const date = new Date( unformattedDate );

    const options = {
      day      : '2-digit',
      month    : '2-digit',
      year     : 'numeric',
      hour     : '2-digit',
      minute   : '2-digit',
      // second   : '2-digit',
      timeZone : 'America/Sao_Paulo' // Brazilian time zone
    };

    const formattedDate = date.toLocaleString( 'pt-BR', options );

    return formattedDate.replace( /,/g, ' ' );
  }
  return 'DATA INVÁLIDA';
};
