
import axios from 'axios';
import { verifyEnvUrl } from 'util/verifyEnvUrl';

const prdURL = 'https://api.acordofechado.com.br/clients';
const devURL = 'https://api.acordofechado.com.br/clients';
// let devURL = 'http://localhost:8080';
// let devURL = 'https://api.acordofechado.com.br/dev-clients';

const clientsApi = axios.create( {
	baseURL: verifyEnvUrl( prdURL, devURL )
} );

clientsApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default clientsApi;
