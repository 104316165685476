import moment from 'moment';
import { format } from 'date-fns';
import  pt  from 'date-fns/locale/pt';
import 'numeral/locales';
import { convertDateDisplay } from 'util/dateUtils';

const INITIAL_STATE = {
	tasks         : columns(),
	tasksCase     : [],
	tasksFiltered : [],
	data          : [],
	cases_ids     : [],
	dates         : {
		today    : moment( new Date() ).format( 'YYYY-MM-DD' ),
		tomorrow : moment( new Date() ).add( 1, 'days' ).format( 'YYYY-MM-DD' ),
		thisWeek : moment( new Date() ).add( 7, 'days' ).format( 'YYYY-MM-DD' ),
	},
	formNewTask: {
		task      : '',
		comment   : '',
		limitDate : '',
	},
	singleTask: false
};

function columns () {
	return [
		{ column: { title: 'Atrasado',     color: '#eb5a46', deals: [] } },
		{ column: { title: 'Hoje',         color: '#ff9f19', deals: [] } },
		{ column: { title: 'Amanhã',       color: '#1781aa', deals: [] } },
		{ column: { title: 'Essa semana',  color: '#82c786', deals: [] } }
	];
}

function getIcons( t ) {
	switch ( t ) {
	case 'capturar contatos': return 'search-in-file';
	case 'pegar contato'    : return 'search-in-file';
	case 'enviar e-mail'    : return 'email';
	case 'ligar'            : return 'headset-mic';
	case 'tratar whatsapp'  : return 'whatsapp';
	case 'cobrar minuta'    : return 'file-text';
	case 'validar minuta'   : return 'assignment-check';
	default                 : return '';
	}
}

function getDeals( d ) {
	return {
		icon          : getIcons( d.task.toLowerCase() ),
		title         : d.task,
		id            : d.case.id,
		date          : convertDateDisplay( d.newLimitDate ),
		dealLimitDate : format( new Date( d.case.deal.dealLimitDate ), 'dd/MM/yyyy', { locale: pt } ),
		clientName    : d.case.client.name,
		status        : d.case.status,
		situation     : d.case.deal.dealStatus,
		lawyer        : d.case.process.processes[0]?.lawyer ? d.case.process.processes[0].lawyer.name : '',
		caseNumber    : d.case.internalId,
		groupName     : d.case.group.name,
		progress      : 0,
		comments      : [ {
			author : '2',
			text   : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus in nulla id maximus. Proin iaculis orci felis, quis placerat sapien posuere id. Nullam convallis ipsum at hendrerit blandit.'
		} ],
		attachments : [],
		assignees   : [
			{ uid: '4' },
			{ uid: '2' },
			{ uid: '5' }
		]
	};
}

const applyDateCategoryFilter = ( tasks, dateCategoryFilterList = [] ) => {
	const isDateCategoryFilterValid = dateCategoryFilterList?.length > 0;

	const dateCategoryFilter = {
		late     : false,
		today    : false,
		tomorrow : false,
		thisWeek : false,
	};

	if ( isDateCategoryFilterValid ) {
		dateCategoryFilterList.map( option => {
			const filter = option.name;
			if ( filter === 'Atrasado' ) {
				dateCategoryFilter.late = true;
			}
			if ( filter === 'Hoje' ) {
				dateCategoryFilter.today = true;
			}
			if ( filter === 'Amanhã' ) {
				dateCategoryFilter.tomorrow = true;
			}
			if ( filter === 'Esta semana' ) {
				dateCategoryFilter.thisWeek = true;
			}
			return option;	
		} );
	}else {
		return tasks;
	}

	const { late, today, tomorrow, thisWeek } = dateCategoryFilter;

	const dateToday = new Date();
	dateToday.setHours( 0, 0, 0, 0 );
	
	const filtered = [];

	for ( const e of tasks ) {

		let taskLimitDate = e.taskLimitDate;
		if ( typeof taskLimitDate !== Date ) {
			taskLimitDate = new Date( e.taskLimitDate ); 
		}
		
		taskLimitDate.setHours( 0, 0, 0, 0 );
		const dayDiff = Math.floor( ( taskLimitDate - dateToday ) / ( 24 * 60 * 60 * 1000 ) );
		
		if ( dayDiff < 0 && late ) {
			filtered.push( e );
		}
		
		else if ( dayDiff === 0 && today ) {
			filtered.push( e );
		}
		
		else if ( dayDiff === 1 && tomorrow ) {
			filtered.push( e );
		}
		
		else if ( dayDiff <= 7 && dayDiff > 1 && thisWeek ) {
			filtered.push( e );
		}
	}
	
	return filtered;
};

export default function Tasks ( state = INITIAL_STATE, action ) {

	//Retorna o array separado em colunas para o kambam das tarefas
	function  renderStructure( e ) {
		const { tasks } = state;
		tasks.map( ( t,i ) => tasks[i].column.deals = [] );
		const dateToday = new Date();
		dateToday.setHours( 0, 0, 0, 0 );

		e.map( ( e ) => {
			let taskLimitDate = e.taskLimitDate;
			if ( typeof taskLimitDate !== Date ) {
				taskLimitDate = new Date( e.taskLimitDate );
			}

			taskLimitDate.setHours( 0, 0, 0, 0 );
			const dayDiff = Math.floor( ( taskLimitDate - dateToday ) / ( 24 * 60 * 60 * 1000 ) );

			if ( dayDiff < 0 ) {
				tasks[0].column.deals.push( getDeals( e.payload ) );
			}

			else if ( dayDiff === 0 ) {
				tasks[1].column.deals.push( getDeals( e.payload ) );
			}

			else if ( dayDiff === 1 ) {
				tasks[2].column.deals.push( getDeals( e.payload ) );
			}

			else if ( dayDiff <= 7 && dayDiff > 1 ) {
				tasks[3].column.deals.push( getDeals( e.payload ) );
			}

			return e;
		} );
		
		return tasks;
	}

	switch ( action.type ) {

	case 'TASKS' : {
		let { tasksCase } = state;
		tasksCase = action.payload;

		return { ...state, tasksCase };
	}

	case 'SET_TASKS':{

		const { payload } = action;

		// ==== FILTRA APENAS OS IDs dos casos e remove os duplicados ==== //

		const cases_ids = [ ...new Set( payload.map( x => x.case_id ) ) ];
		cases_ids.filter( ( item, index ) => cases_ids.indexOf( item ) === index );
		cases_ids.reduce( ( unique, item ) => unique.includes( item ) ? unique : [ ...unique, item ],[] );

		// ==== FILTRA APENAS OS IDs dos casos e remove os duplicados ==== //

		let data = [];
		payload.filter( e => e.task.toLowerCase() !== 'n/a' ).map( e =>
		{
			return data.push( {
				id              : e.id,
				case_id         : e.case_id,
				internalId      : e.case.internalId,
				completed_by    : e.completed_by,
				clientName      : e.case.client.name,
				groupName       : e.case.group.name,
				lawyerName      : e.case.process.processes[0]?.lawyer ? e.case.process.processes[0].lawyer.name : '',
				tasks           : e.task,
				processualState : e.case.processualState,
				users           : '',
				dealStatus      : e.case.deal.dealStatus,
				taskLimitDate   : e.newLimitDate !== '' ? e.newLimitDate : new Date(),
				dealLimitDate   : e.case.deal.dealLimitDate,
				payload         : e
			} );
		} );

		const tasks = renderStructure( data );
		return { ...state, tasks, data, tasksFiltered: action.payload, cases_ids };
	}

	case 'SET_FILTERS':{
		let { payload :{ searchFilter, singleDate }, userList = [] } = action;
		const { payload } = action;
		const dateCategoryFilter = payload?.select?.dateCategory?.options ? payload?.select?.dateCategory?.options : [];
		
		let key_single_date = singleDate && singleDate.dealLimitDate !== null ? singleDate.dealLimitDate : new Date( '2200-12-31' );
		key_single_date = format( new Date( key_single_date ), 'yyyy-MM-dd' );

		const { data } = state;

		let filter = data.filter( e => e.dealLimitDate <= key_single_date
                                    &&  ( 
                                    	
                                    	e.internalId.indexOf( `${searchFilter}` ) !== -1
                                          || e.clientName.toLowerCase().indexOf( `${searchFilter}` ) !== -1
                                          || e.groupName.toLowerCase().indexOf( `${searchFilter}` ) !== -1
                                          || e.users.toLowerCase().indexOf( `${searchFilter}` ) !== -1
                                          || e.dealStatus.toLowerCase().indexOf( `${searchFilter}` ) !== -1
                                    ) );

		let obj = Object.values( action.payload.select );

		obj.map( ob => {
			filter = ob.options.length > 0 ? filter.filter( e =>
				e[ob.name] ? ob.options.some( el => !el || el.name.toLowerCase() === e[ob.name].toLowerCase() ) : []  ) : filter;
			return filter;
		} );

		const userFilter = payload?.select?.users?.options ? payload?.select?.users?.options : [];

		if ( userFilter.length > 0 ) {

			const userFilterId = [];

			for ( const selectedUser of userFilter ) {
				for ( const option of userList ) {
					if ( selectedUser.name === option.name ) {
						userFilterId.push( option.id );
					}
				}
			}

			const filteredByUserTasks = [];
			
			for ( const userId of userFilterId ) { //eslint-disable-next-line
				filter.map( task => {
					if ( task.completed_by === userId ) {
						filteredByUserTasks.push( task );
					}
				} );
			}

			filter = filteredByUserTasks;
		}

		if ( dateCategoryFilter.length > 0 ) {
			filter = applyDateCategoryFilter( filter, dateCategoryFilter );
		}

		const tasks = renderStructure( filter );

		// ==== FILTRA APENAS OS IDs dos casos e remove os duplicados ==== //
		const cases_ids = [ ...new Set( filter.map( x => x.case_id ) ) ];
		cases_ids.filter( ( item, index ) => cases_ids.indexOf( item ) === index );
		cases_ids.reduce( ( unique, item ) => unique.includes( item ) ? unique : [ ...unique, item ],[] );


		return { ...state, tasksFiltered: filter, tasks, cases_ids };
	}

	case 'FORM_NEW_TASK': {
		return { ...state, formNewTask: { ...action.payload } };
	}

	case 'TASKS_FILTERED': {
		return { ...state, tasksFiltered: action.payload };
	}

	case 'SINGLE_TASK' : {

		return { ...state, singleTask: { ...action.payload } };
	}

	default: return state;
	}
}
