import React, { useEffect } from 'react';
import { DialerClosedInteractible } from './styles';
import { HeadsetMic } from '@material-ui/icons';
import { useDialerContext } from '../DialerContext';
import { validateExperIpLogin } from 'util/isAdmin';

const ClosedDialer = ( { handleOpen } ) => {
	const { 
		authenticateSIP,
	} = useDialerContext();

	useEffect( () => {
		const error = localStorage.getItem( 'experip-token-error' );
		const validToken = validateExperIpLogin();
		if ( validToken && error !== '0' ) {
			authenticateSIP();
		}
	}, [] ); //eslint-disable-line
	return (
		<DialerClosedInteractible onClick={handleOpen}>
			<HeadsetMic fontSize='large' />
		</DialerClosedInteractible>
	);
};

export default ClosedDialer;