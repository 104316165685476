import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #626263;
  }

  .loader {
    border: 2px solid transparent;
    border-top: 2px solid #004E7B;
    border-right: 2px solid #004E7B;
    padding: 0px;
    margin: 0px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: ${rotate} 1s linear infinite;
  }
`;
