import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import settings from './Settings';
import auth from './Auth';
import clients from './Clients';
import persons from './Persons';
import cases from './Cases';
import tasks from './Tasks';
import notifyAlert from './NotifyAlert';
import selectReducer from './Filters';
import bots from './Bots';

export default history =>
	combineReducers( {
		router: connectRouter( history ),
		settings,
		auth,
		clients,
		persons,
		cases,
		tasks,
		selectReducer,
		bots,
		notifyAlert
	} );
