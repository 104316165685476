import { applyMiddleware, createStore } from 'redux';
import reducers from '../reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';


const history = createBrowserHistory();
const routeMiddleware = routerMiddleware( history );
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];


export default function configureStore( initialState ) {
  const store = createStore( reducers( history ), initialState,
    applyMiddleware( ...middlewares ) );

  sagaMiddleware.run( rootSaga );

  if ( module.hot ) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept( '../reducers', () => {
      const nextRootReducer = require( '../reducers' );
      store.replaceReducer( nextRootReducer );
    } );
  }
  return store;
}
export { history };
