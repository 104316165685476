import React from 'react';
import { LoadingCenterDivContainer } from './styles';

const LoadingCenterDiv = ( { title } ) => {
  return (
    <LoadingCenterDivContainer>
      <h2 className='loading-section__title'>{title || 'Carregando...'}</h2>
      <div className='loader'></div>
    </LoadingCenterDivContainer>
  );
};

export default LoadingCenterDiv;