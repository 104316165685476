import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../api/credentials';
import {
	SIGNOUT_USER,
	UPDATE_USER
} from 'constants/ActionTypes';
import { showAuthMessage, userSignOutSuccess, userUpdateSuccess } from 'actions/Auth';
import { STORAGE_REGISTER_V2_ID, STORAGE_REGISTER_V2_URL } from 'constants/registerV2';

const updateRequest = async ( user ) =>
	await auth.updateUser( user )
		.then( body => {
			if( body.data.error ) {
				throw body.data.error;
			}
			return body.data;
		} );

function* signOut() {

	localStorage.removeItem( 'user_id' );
	localStorage.removeItem( 'af-token' );
	localStorage.removeItem( 'af-notification' );
	localStorage.removeItem( 'redirect-url' );
	localStorage.removeItem( 'token-experip' );
	localStorage.removeItem( 'experip-token-error' );
	localStorage.removeItem( STORAGE_REGISTER_V2_URL );
	localStorage.removeItem( STORAGE_REGISTER_V2_ID );

	yield put( userSignOutSuccess( signOutUser ) );
}

function* update( { payload } ) {
	// const {id, name, email} = payload;
	try {
		const updatedUser = yield call( updateRequest, payload );
		if( updatedUser.message ) {
			yield put( showAuthMessage( updatedUser.message ) );
		} else {
			if( payload.id === auth.getJwtId( updatedUser.jwt ) ) {
				localStorage.setItem( 'user_id', updatedUser.jwt );
				return yield put( userUpdateSuccess( updatedUser.jwt ) );
			}
			yield put( showAuthMessage( 'Usuário atualizado com sucesso.' ) );
		}
	} catch ( error ) {
		yield put( showAuthMessage( error.response.data.error ) );
	}
}


export function* signOutUser() {
	yield takeEvery( SIGNOUT_USER, signOut );
}

export function* updateUser() {
	yield takeEvery( UPDATE_USER, update );
}

export default function* rootSaga() {
	yield all( [
		fork( signOutUser ),
		fork( updateUser )
	] );
}
