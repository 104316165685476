import styled from 'styled-components';

export const Overlay = styled.div`
  left: 0;
  top: 0;
  z-index: 1111;

  background: rgba(0,0,0,0.8);
  backdrop-filter: blur(4.5px);

  width: 100%;
  height: 100%;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBody = styled.div`
  background-color: #FFFF;
  /* width: 480px; */
  max-height: 95%;
  overflow-y: auto;
  max-width: 85%;
  border-radius: 0px;
  position: relative;
  padding: 32px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      line-height: 0;
      border: 0;
      background: transparent;
      position: absolute;
      top: 12px;
      right: 12px;
    }

  }
`;
