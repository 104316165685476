import { auth } from 'api/credentials';
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  UPDATE_USER_SUCESS
} from 'constants/ActionTypes';

const TENANT_ADMIN = [3];

const INIT_STATE = {
  loader       : false,
  alertMessage : '',
  showMessage  : false,
  initURL      : '',
  authUser     : localStorage.getItem( 'af-token' ),
  userName     : auth.getJwtName( localStorage.getItem( 'af-token' ) ),
  userColor    : auth.getJwtColor( localStorage.getItem( 'user_id' ) ),
  userAcess    : auth.getJwtAccess( localStorage.getItem( 'user_id' ) ),
  userLogin    : auth.getJwtLogin( localStorage.getItem( 'af-token' ) ),
  userId       : auth.getJwtId( localStorage.getItem( 'user_id' ) ),
  isAdmin      : TENANT_ADMIN.includes( auth.getJwtTenantId( localStorage.getItem( 'af-token' ) ) ),
  ramal        : auth.getJwtRamal( localStorage.getItem( 'af-token' ) )
};

export default function Auth( state = INIT_STATE, action ) {

  switch ( action.type ) {
  case UPDATE_USER_SUCESS: {
    return state.userId === auth.getJwtId( action.payload )
      ? {
        ...state,
        loader    : false,
        authUser  : action.payload,
        // initURL: '/app/profile',
        userName  : auth.getJwtName( action.payload ),
        userColor : auth.getJwtColor( action.payload ),
        userAcess : auth.getJwtAccess( action.payload ),
        userLogin : auth.getJwtLogin( action.payload ),
        isAdmin   : TENANT_ADMIN.includes( auth.getJwtTenantId( action.payload ) ),
        // userId    : auth.getJwtId( action.payload ),
        ramal     : auth.getJwtRamal( action.payload ),
        userId    : auth.getUserId( action.payload.token )

      }
      : {
        ...state,
        loader: false
      };
  }
  case SIGNUP_USER_SUCCESS: {
    return {
      ...state,
      loader: false
    };
  }
  case SIGNIN_USER_SUCCESS: {

    return {
      ...state,
      loader    : false,
      authUser  : action.payload.token,
      userName  : auth.getJwtName( action.payload.token ),
      userColor : auth.getJwtColor( action.payload.token ),
      userAcess : auth.getJwtAccess( action.payload.token ),
      userLogin : action.payload.email,
      // userId    : auth.getJwtId( action.payload.token ),
      isAdmin   : TENANT_ADMIN.includes( auth.getJwtTenantId( action.payload ) ),
      ramal     : auth.getJwtRamal( action.payload.token ),
      userId    : auth.getUserId( action.payload.token )
    };
  }
  case INIT_URL: {
    return {
      ...state,
      initURL: action.payload
    };
  }
  case SIGNOUT_USER_SUCCESS: {
    return {
      ...state,
      authUser : null,
      initURL  : '/app/visao-geral',
      loader   : false,
      userName : ''
    };
  }

  case SHOW_MESSAGE: {
    return {
      ...state,
      alertMessage : action.payload,
      showMessage  : true,
      loader       : false
    };
  }
  case HIDE_MESSAGE: {
    return {
      ...state,
      alertMessage : '',
      showMessage  : false,
      loader       : false
    };
  }

  case ON_SHOW_LOADER: {
    return {
      ...state,
      loader: true
    };
  }
  case ON_HIDE_LOADER: {
    return {
      ...state,
      loader: false
    };
  }
  default:
    return state;
  }
}
