import axios from 'axios';
import { verifyEnvUrl } from 'util/verifyEnvUrl';

const PROD_URL = 'https://api.acordofechado.com.br/whatsapp';
const DEV_URL = 'https://us-central1-acordo-fechado-dev.cloudfunctions.net/whatsAppApiDev';

const whatsAppApi = axios.create( {
	baseURL: verifyEnvUrl( PROD_URL, DEV_URL ),
} );

whatsAppApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default whatsAppApi;
