import React, { useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	AppBar,
	Toolbar,
	IconButton
} from '@material-ui/core';

import { Badge } from '@mui/material';
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';

import NotificationsIcon from '@material-ui/icons/Notifications';

import {
	BELOW_THE_HEADER,
	HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';

import { toggleCollapsedNav } from 'actions/Setting';
import { userSignOut } from 'actions/Auth';
import MinimalNotificationList from 'app/routes/Notifications/Components/MinimalNotificationList';
import { useQuery } from 'react-query';
import { useNotificationRequests } from 'app/routes/Notifications/hooks/notificationRequests';
import { useHeaderActions } from './hooks/useHeaderActions';
import { useNPSContext } from '../../context/NpsContext';
import { CustomerCenter } from './components/customerCenter';
import { MenuUser } from './components/menuUser';
import SearchCase from './components/searchCase';
import { YoutubeMovies } from './components/youtubeMovies';

// import { Notification } from './components/Notification';

function Header( props ) {
	const { state, handlers } = useHeaderActions();
	const { execute } = useNPSContext();
	const firstRender = useRef( true );
	const { auth, settings } = useSelector( state => state );

	useQuery( {
		queryKey : [ 'last-nps' ],
		queryFn  : execute,
	} );

	useEffect( () => {
		if ( !firstRender.current ) {
			return;
		}

	}, [] );

	const { isOpenVideos, openHelpCenter, openMenuUser } = state;
	const { navigationStyle, horizontalNavPosition } = settings;

	const { getAllNotifications } = useNotificationRequests();

	const { data } = useQuery( {
		queryKey        : [ 'notifications' ],
		queryFn         : getAllNotifications,
		refetchInterval : 30000
	} );

	return (
		<AppBar
			className={`${
				navigationStyle === HORIZONTAL_NAVIGATION &&
        horizontalNavPosition === BELOW_THE_HEADER
					? 'app-main-header-top'
					: ''
			}`}
		>
			<Toolbar style={{ backgroundColor: '#1781aa' }} className="app-toolbar" disableGutters={false}>
				{navigationStyle === HORIZONTAL_NAVIGATION ? (
					<div className="d-block d-md-none pointer mr-3" onClick={props.toggleCollapsedNav}>
						<span className="jr-menu-icon">
							<span className="menu-icon"/>
						</span>
					</div>
				) : (
					<IconButton className={'jr-menu-icon mr-3'} aria-label="Menu" onClick={props.toggleCollapsedNav}>
						<span className="menu-icon"/>
					</IconButton>
				)}
				<SearchCase />
				<ul className="header-notifications list-inline ml-auto">
					<li className="list-inline-item" >
						<Dropdown
							className="quick-menu"
							isOpen={state.appNotification}
							toggle={() => handlers.setAppNotification( !state.appNotification )}>
							<DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
								<IconButton className="icon-btn">
									<Badge badgeContent={data?.filter( notice => notice.read === false )?.length} color="error">
										<NotificationsIcon />
									</Badge>
								</IconButton>
							</DropdownToggle>
							<DropdownMenu right style={{ padding: 0 }}>
								<MinimalNotificationList />
							</DropdownMenu>
						</Dropdown>
					</li>
					<li className="list-inline-item" >
						<CustomerCenter handlers={handlers} openHelpCenter={openHelpCenter} />
					</li>
					<li className="list-inline-item">
						<MenuUser
							handlers={handlers}
							openMenuUser={openMenuUser}
							auth={auth}
							userSignOut={props.userSignOut} />
					</li>
				</ul>
				<div className="ellipse-shape"></div>
			</Toolbar>

			<YoutubeMovies open={isOpenVideos} closeVideos={() => handlers.setIsOpenVideos( false )} />
		</AppBar>
	);
}


export default withRouter(
	connect( null, { toggleCollapsedNav, userSignOut } )( Header )
);
