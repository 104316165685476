import { formatDate } from 'util/utils.js';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales';
numeral.locale( 'pt-br' );

const INITIAL_STATE = {
	filters: {
		searchFilter : '',
		status       : '',
		filter       : false
	},
	pagination: {
		size  : 15,
		page  : 1,
		total : 0
	},
	massEdit    : [],
	cases       : [],
	casesFilter : [],
	editCases   : false,
	listFilters : {
		clientName      : [],
		groupName       : [],
		status          : [],
		users           : [],
		dealStatus      : [],
		tasks           : [],
		processualState : [{ name: 'Acordo Prévio' }, { name: 'Pós Sentença' }],
		caseType        : [{ name: 'PASSIVO' }, { name: 'ATIVO' }]
	},
	modalLote    : false,
	columnsTable : [
		{
			name       : 'Status',
			checked    : true,
			fnName     : 'status',
			fnNameEdit : false
		},
		{
			name       : 'Nome da Empresa',
			checked    : true,
			fnName     : 'groupName',
			fnNameEdit : false
		},
		{
			name       : 'Nome do Cliente',
			checked    : true,
			fnName     : 'clientName',
			fnNameEdit : false
		},
		{
			name       : 'Nome do Autor',
			checked    : true,
			fnName     : 'authorName',
			fnNameEdit : false
		},
		{
			name       : 'Processo',
			checked    : true,
			fnName     : 'process',
			fnNameEdit : false
		},
		{
			name       : 'Processo Pasta Cliente',
			checked    : true,
			fnName     : 'clientFolder',
			fnNameEdit : false
		},
		{
			name       : 'Caso',
			checked    : true,
			fnName     : 'internalId',
			fnNameEdit : false
		},
		{
			name       : 'Resumo da Proposta',
			checked    : true,
			fnName     : 'resumeProposal',
			fnNameEdit : false
		},
		{
			name       : 'Proposta Máxima',
			checked    : true,
			fnName     : 'maxProposal',
			fnNameEdit : false
		},
		{
			name       : 'Obrigação a Fazer',
			checked    : true,
			fnName     : 'dutyToDo',
			fnNameEdit : false
		},
		{
			name       : 'Situação do Caso',
			checked    : true,
			fnName     : 'dealStatus',
			fnNameEdit : 'dealStatusEdit'
		},
		{
			name       : 'Prazo',
			checked    : true,
			fnName     : 'dealLimitDate',
			fnNameEdit : 'dealLimitDateEdit'
		},
		{
			name       : 'Contraproposta do Autor',
			checked    : true,
			fnName     : 'authorCounterProposalValue',
			fnNameEdit : 'authorCounterProposalValueEdit'
		},
		{
			name       : 'Contraproposta de Obrigação a Fazer',
			checked    : true,
			fnName     : 'authorCounterProposalText',
			fnNameEdit : false
		},
		{
			name       : 'Contraproposta do Réu',
			checked    : true,
			fnName     : 'clientCounterProposalValue',
			fnNameEdit : 'clientCounterProposalValueEdit'
		},
		{
			name       : 'Tarefa',
			checked    : true,
			fnName     : 'tasks',
			fnNameEdit : false
		},
		{
			name       : 'Fase Processual',
			checked    : true,
			fnName     : 'processualState',
			fnNameEdit : 'processualStateEdit'
		},
		{
			name       : 'Advogado',
			checked    : true,
			fnName     : 'lawyer',
			fnNameEdit : false
		},
		{
			name       : 'OAB',
			checked    : true,
			fnName     : 'oab',
			fnNameEdit : false
		},
		{
			name       : 'E-mail Advogado',
			checked    : true,
			fnName     : 'lawyerEmail',
			fnNameEdit : false
		},
		{
			name       : 'E-mail Autor',
			checked    : true,
			fnName     : 'authorEmail',
			fnNameEdit : false
		},
		{
			name       : 'Telefone Advogado',
			checked    : true,
			fnName     : 'lawyerPhone',
			fnNameEdit : false
		},
		{
			name       : 'WhatsApp Advogado',
			checked    : true,
			fnName     : 'lawyerWhatsapp',
			fnNameEdit : false
		},
		{
			name       : 'Telefone autor',
			checked    : false,
			fnName     : 'authorPhone',
			fnNameEdit : false
		},
		{
			name       : 'WhatsApp Autor',
			checked    : false,
			fnName     : 'authorWhatsapp',
			fnNameEdit : false
		},
		{
			name       : 'Data Acordo',
			checked    : false,
			fnName     : 'closedDealDate',
			fnNameEdit : false
		},
		{
			name       : 'Autor CPF',
			checked    : false,
			fnName     : 'authorCpf',
			fnNameEdit : false
		},
		{
			name       : 'Processo Estado',
			checked    : false,
			fnName     : 'state',
			fnNameEdit : false
		},
		{
			name       : 'Processo Comarca',
			checked    : false,
			fnName     : 'district',
			fnNameEdit : false
		},
		{
			name       : 'Processo Vara',
			checked    : false,
			fnName     : 'judgement',
			fnNameEdit : false
		},
		{
			name       : 'Processo Objeto',
			checked    : false,
			fnName     : 'object',
			fnNameEdit : false
		},
		{
			name       : 'Processo Valor',
			checked    : false,
			fnName     : 'valueProcess',
			fnNameEdit : false
		},
		{
			name       : 'Negociação Proposta',
			checked    : false,
			fnName     : 'proposals',
			fnNameEdit : false
		},
		{
			name       : 'Negociação Proposta Atual',
			checked    : false,
			fnName     : 'lastProposal',
			fnNameEdit : false
		},
		{
			name       : 'Negociação Prazo Pagamento',
			checked    : false,
			fnName     : 'paymentLimitDate',
			fnNameEdit : false
		},
		{
			name       : 'Negociação Forma Pagamento Escolhida',
			checked    : false,
			fnName     : 'selectedPaymentType',
			fnNameEdit : false
		},
		{
			name       : 'Negociação Contra proposta de obrigação a fazer',
			checked    : false,
			fnName     : 'authorCounterProposalText',
			fnNameEdit : false
		},
		{
			name       : 'Negociação Obrigação Fazer',
			checked    : false,
			fnName     : 'dutyToDo',
			fnNameEdit : false
		},
		{
			name       : 'Negociação Prazo Obrigação',
			checked    : false,
			fnName     : 'dutyLimitDate',
			fnNameEdit : false
		},
		{
			name       : 'Data de cadastro',
			checked    : false,
			fnName     : 'createdAt',
			fnNameEdit : false
		},
		{
			name       : 'Mês de cadastro',
			checked    : false,
			fnName     : 'monthCreatedAt',
			fnNameEdit : false
		},
		{
			name       : 'Data do acordo',
			checked    : false,
			fnName     : 'closedDealDate',
			fnNameEdit : false
		},
		{
			name       : 'Mês do acordo',
			checked    : false,
			fnName     : 'monthClosedDealDate',
			fnNameEdit : false
		},
		{
			name       : 'Situação do E-mail de Proposta',
			checked    : false,
			fnName     : 'default',
			fnNameEdit : false
		},
		{
			name       : 'Datas de Envio dos E-mails de Propostas',
			checked    : false,
			fnName     : 'default',
			fnNameEdit : false
		},
		{
			name       : 'Datas das Ligações',
			checked    : false,
			fnName     : 'call',
			fnNameEdit : false
		},
		{
			name       : 'Observação',
			checked    : false,
			fnName     : 'observation',
			fnNameEdit : false
		},
		{
			name       : 'Status Bot',
			checked    : false,
			fnName     : '',
			fnNameEdit : false
		},
		{
			name       : 'Tipo de ação',
			checked    : true,
			fnName     : 'caseType',
			fnNameEdit : false
		},
		{
			name       : 'Link réu',
			checked    : false,
			fnName     : 'linkReu',
			fnNameEdit : false
		},
		{
			name       : 'Link autor',
			checked    : false,
			fnName     : 'linkAuthor',
			fnNameEdit : false
		}
	],
	filterColumns : [],
	select        : {
		situacaoCaso    : [],
		tasks           : [],
		processualState : ['acordo prévio', 'pós sentença'],
		caseType        : ['passivo', 'ativo']
	},
	submit           : [],
	singleSubmit     : [],
	updateSingleCase : [],
	proposalMax      : { value: '', edited: false }
};

export default function Cases( state = INITIAL_STATE, action ) {
	switch ( action.type ) {
	case 'DEFINE_SITUATIONS': {
		let dealStatus = [];
		if ( action.payload?.dealStatus ) {
			dealStatus = action.payload.dealStatus.sort( ( a, b ) => a.dealStatus.localeCompare( b.dealStatus ) );
		}
		return {
			...state,
			select      : { ...state.select },
			listFilters : { ...state.listFilters, ...action.payload, dealStatus }
		};
	}

	case 'ADD_CASE': {
		let {
			listFilters: { clientName, groupName },
			submit,
			cases
		} = state;
		clientName = [];
		groupName = [];
		submit = [];
		cases = [];

		action.payload.map( cas => {
			clientName.indexOf( cas.client?.name ) === -1 &&
          clientName.push( cas.client?.name );
			groupName.indexOf( cas.group?.name ) === -1 &&
          groupName.push( cas.group?.name );

			cases.push( {
				...cas,
				clientName : cas.client?.name,
				groupName  : cas.group?.name,
				dealStatus : cas.deal?.status
			} );

			submit.push( {
				case: {
					id              : cas.case.id,
					status          : cas.case.status,
					processualState : cas.case.processualState,
					// task: undefined,
					edited          : false
				},
				deal: {
					id                         : cas.deal?.id,
					dealLimitDate              : cas.deal?.limitDate,
					closedDealDate             : cas.deal?.closedDealDate,
					authorCounterProposalValue : cas.deal?.authorCounterProposalValue,
					authorCounterProposalText  : cas.deal?.authorCounterProposalText,
					clientCounterProposalValue : cas.deal?.clientCounterProposalValue,
					paymentLimitDate           : cas.deal?.paymentLimitDate,
					// clientCounterProposalText: cas.deal?.clientCounterProposalText,
					dealStatus                 : cas.deal?.status,
					proposals                  : cas.deal?.proposals,
					edited                     : false
				},

				person: {
					id              : cas?.author && cas?.author.id,
					name            : cas.author?.name,
					email           : cas?.author && cas?.author.email,
					telephones      : cas?.author.telephones,
					whatsappNumbers : cas?.author ? cas?.author.whatsapp : [],
					edited          : false
				},
				lawyer: {
					name            : cas.lawyer?.name,
					id              : cas?.lawyer && cas?.lawyer.id,
					email           : cas?.lawyer && cas.lawyer.email,
					telephones      : cas?.lawyer && cas?.lawyer.telephones,
					whatsappNumbers : cas?.lawyer ? cas?.lawyer.whatsapp : [],
					edited          : false
				},
				process: {
					id           : cas.process?.id,
					clientFolder : cas.process?.clientFolder,
					// value: cas.process?.value,
					// observation: cas.process?.observation,
					edited       : false
				}
			} );
			return cas;
		} );

		cases.sort( ( a, b ) => a.id - b.id );
		submit.sort( ( a, b ) => a.case.id - b.case.id );

		return { ...state, cases, casesFilter: cases, submit };
	}
	case 'SET_FILTER': {
		let {
			dealStatus,
			tasks,
			selectedDate: {
				closedDealDateEnd,
				dtCad: { 0: init, 1: end },
				dtAcordo: { 0: dtAcordoInit, 1: dtAcordoEnd }
			}
		} = action.payload;

		init = init
			? formatDate( init )
			: moment( new Date( '1900,01,01' ), 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
		end = end
			? formatDate( end )
			: moment( new Date( '2100,12,31' ), 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );

		closedDealDateEnd = closedDealDateEnd
			? formatDate( closedDealDateEnd )
			: null;

		dtAcordoInit = dtAcordoInit
			? formatDate( dtAcordoInit )
			: moment( new Date( '1900,01,01' ), 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
		dtAcordoEnd = dtAcordoEnd
			? formatDate( dtAcordoEnd )
			: moment( new Date( '2100,12,31' ), 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );

		let search =
        action.payload.searchFilter !== undefined
        	? action.payload.searchFilter
        	: state.filters.searchFilter;
		search = search.toLowerCase();

		let cases = state.cases.filter( cas =>
			closedDealDateEnd !== null
				? moment( cas.deal.closedDealDate, 'YYYY-MM-DD' ).format(
					'YYYY-MM-DD'
				) <= closedDealDateEnd &&
          moment( cas.deal.dealLimitDate, 'DD/MM/YYYY' ).format( 'YYYY-MM-DD' ) >=
          dtAcordoInit &&
          moment( cas.deal.dealLimitDate, 'DD/MM/YYYY' ).format( 'YYYY-MM-DD' ) <=
          dtAcordoEnd &&
          moment( cas.createdAt, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' ) >= init &&
          moment( cas.createdAt, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' ) <= end &&
          ( cas.clientName.toLowerCase().indexOf( search ) !== -1 ||
            ( cas.process.processes.length > 0 &&
              cas.process.processes[0].person.name
              	.toLowerCase()
              	.indexOf( search ) !== -1 ) || // Nome autor
            cas.process.processNumber.indexOf( search ) !== -1 ||
            cas.dealStatus.toLowerCase().indexOf( search ) !== -1 ||
            cas.status.toLowerCase().indexOf( search ) !== -1 ||
            ( cas.tasks.filter( t => !t.completed )[0] &&
              cas.tasks
              	.filter( t => !t.completed )[0]
              	.task.toLowerCase()
              	.indexOf( search ) !== -1 ) ||
            cas.groupName.toLowerCase().indexOf( search ) !== -1 ||
            cas.internalId.toLowerCase().indexOf( search ) !== -1 )
				: moment( cas.deal.dealLimitDate, 'DD/MM/YYYY' ).format( 'YYYY-MM-DD' ) >=
          dtAcordoInit &&
          moment( cas.deal.dealLimitDate, 'DD/MM/YYYY' ).format( 'YYYY-MM-DD' ) <=
          dtAcordoEnd &&
          moment( cas.createdAt, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' ) >= init &&
          moment( cas.createdAt, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' ) <= end &&
          ( cas.clientName.toLowerCase().indexOf( search ) !== -1 ||
            ( cas.process.processes.length > 0 &&
              cas.process.processes[0].person.name
              	.toLowerCase()
              	.indexOf( search ) !== -1 ) || // Nome autor
            cas.process.processNumber.indexOf( search ) !== -1 ||
            cas.dealStatus.toLowerCase().indexOf( search ) !== -1 ||
            cas.status.toLowerCase().indexOf( search ) !== -1 ||
            ( cas.tasks.filter( t => !t.completed )[0] &&
              cas.tasks
              	.filter( t => !t.completed )[0]
              	.task.toLowerCase()
              	.indexOf( search ) !== -1 ) ||
            cas.groupName.toLowerCase().indexOf( search ) !== -1 ||
            cas.internalId.toLowerCase().indexOf( search ) !== -1 )
		);

		let obj = Object.values( action.payload.select );

		obj.map( ob => {
			if ( ob.name === 'tasks' ) {
				cases =
            ob.options.length > 0
            	? cases.filter( e => {
            		let task = e.tasks.filter( e => !e.completed )[0];
            		task = task ? task.task : '';
            		return ob.options.some( el => el === task );
            	} )
            	: cases;
			} else
				cases =
            ob.options.length > 0
            	? cases.filter( e =>
            		ob.options.some(
            			el => el.toLowerCase() === e[ob.name].toLowerCase()
            		)
            	)
            	: cases;
			return cases;
		} );
		return {
			...state,
			casesFilter : cases,
			filters     : { ...state.filters, searchFilter: search },
			select      : { ...state.select, situacaoCaso: dealStatus, tasks }
		};
	}

	case 'FILTER_CASE': {
		return { ...state, ...action.payload };
	}

	case 'EDIT_MASS_CASE': {
		return { ...state, submit: action.payload };
	}

	case 'SINGLE_FILTER': {
		let filters = { ...state.filters, ...action.payload };

		return { ...state, filters: filters };
	}

	case 'EDIT_CASES':
		return { ...state, editCases: action.payload };

	case 'MODAL_LOTE':
		return { ...state, modalLote: action.payload };

	case 'FILTER_COLUMNS': {
		const { col, func } = action.payload;

		const cols = func === null ? col : [];
		func === 'deselectAll' &&
        col.map( col => cols.push( { ...col, checked: false } ) );
		func === 'ckeckAll' &&
        col.map( col => cols.push( { ...col, checked: true } ) );

		return { ...state, columnsTable: cols };
	}
	case 'SINGLE_CASE':{
		const { payload } = action;
		const submitCase = [
			{
				case: {
					internalId      : payload.internalId,
					id              : payload.id,
					status          : payload.status,
					processualState : payload.processualState,
					task            : payload.task,
					edited          : false
				},
				deal: {
					id                         : payload.deal.id,
					dealLimitDate              : payload.deal.dealLimitDate,
					closedDealDate             : payload.deal.closedDealDate,
					authorCounterProposalValue : payload.deal.authorCounterProposalValue,
					authorCounterProposalText  : payload.deal.authorCounterProposalText,
					clientCounterProposalValue : payload.deal.clientCounterProposalValue,
					clientCounterProposalText  : payload.deal.clientCounterProposalText,
					dutyLimitDate              : payload.deal.dutyLimitDate,
					paymentLimitDate           : payload.deal.paymentLimitDate,
					dutyToDo                   : payload.deal.dutyToDo,
					paymentType                : payload.deal.paymentType,
					selectedPaymentType        : payload.deal.selectedPaymentType || '',
					dealStatus                 : payload.deal.dealStatus,
					lastProposal               : payload.deal.lastProposal,
					proposals                  : payload.deal.proposals,
					percentSuccubenceFees      : payload.deal.percentSuccubenceFees,
					edited                     : false
				},
				group: {
					...payload.group
				},
				person: {
					id              : payload.process.processes[0].person.id,
					email           : payload.process.processes[0].person.email,
					telephones      : payload.process.processes[0].person.telephones,
					whatsappNumbers : payload.process.processes[0].person
						? payload.process.processes[0].person.whatsappNumbers
						: [],
					edited: false
				},
				lawyer: {
					name:
              payload.process.processes[0].lawyer &&
              payload.process.processes[0].lawyer.name,
					id:
              payload.process.processes[0].lawyer &&
              payload.process.processes[0].lawyer.id,
					email:
              payload.process.processes[0].lawyer &&
              payload.process.processes[0].lawyer.email,
					telephones:
              payload.process.processes[0].lawyer &&
              payload.process.processes[0].lawyer.telephones,
					whatsappNumbers: payload.process.processes[0].lawyer
						? payload.process.processes[0].lawyer.whatsappNumbers
						: [],
					edited: false
				},
				process: {
					id            : payload.process.id,
					clientFolder  : payload.process.clientFolder,
					value         : payload.process.value,
					observation   : payload.process.observation,
					processNumber : payload.process.processNumber,
					edited        : false
				}
			}
		];
		return {
			...state,
			singleSubmit : submitCase,
			proposalMax  : { ...state.proposalMax, value: payload.deal.proposals }
		};}

	case 'UPDATE_SINGLE_CASE':{
		const updateSingleCase = action.payload;
		return { ...state, updateSingleCase };
	}
	case 'UPDATE_PROPOSALMAX':
		return {
			...state,
			proposalMax: {
				value  : action.payload.value,
				edited : action.payload.edited
			}
		};

	case 'SET_LIMIT_PAGE':
		return {
			...state,
			pagination: { ...state.pagination, ...action.payload }
		};
	default:
		return state;
	}
}
