import React from 'react';
import { NotificationListSkeletonContainer } from './styles';
import NotificationCardSkeleton from './NotificationCardSkeleton';

const NotificationListSkeleton = () => {
  return (
    <NotificationListSkeletonContainer>
      <NotificationCardSkeleton />
      <NotificationCardSkeleton />
      <NotificationCardSkeleton />
      <NotificationCardSkeleton />
      <NotificationCardSkeleton />
      <NotificationCardSkeleton />
      <NotificationCardSkeleton />
    </NotificationListSkeletonContainer>
  );
};

export default NotificationListSkeleton;