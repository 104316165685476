import React from 'react';
import { DialerConnectionText, DialerNumberDisplay, DialerPopupContainer, DialerStatusTitle, DialerTimer, GreenSwitch } from './styles';
import { Box } from '@material-ui/core';
import LoadingCenterDiv from 'newComponents/LoadingCenterDiv';
import { useDialerContext } from '../DialerContext';
import { Close, Info } from '@material-ui/icons';
import CallActions from './CallActions';
import Keyboard from './Keyboard';
import { MinimalRoundedButton } from 'newComponents/FilterDefault/FilterClearButton/styles';

const DialerPopup = ( { handleClose } ) => {
	const { 
		isLoading, 
		dialerStatus,
		handleConnection,
		callNumber,
		callInProgress,
		getTimerFormatted,
		isConnecting,
		authSuccess,
		getError,
	} = useDialerContext();

	if ( isLoading ) {
		return (
			<DialerPopupContainer >
				<Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'} >
					<LoadingCenterDiv title={'Autenticando...'} />
				</Box>
			</DialerPopupContainer>
		);
	}

	if ( !authSuccess ) {
		return (
			<DialerPopupContainer >
				<Box display={'flex'} flexDirection={'row'} justifyContent={'end'} width={'100%'} style={{ padding: '16px' }}
				>
					<Close onClick={handleClose} style={{ cursor: 'pointer', justifySelf: 'start', color: '#7A7A7A' }} fontSize='small' />
				</Box>
				<Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'} height={'100%'} >
					<DialerNumberDisplay>Autenticação falhou, verifique os dados de login.</DialerNumberDisplay>
					<MinimalRoundedButton type='button' onClick={getError}>Copiar erro</MinimalRoundedButton>
				</Box>
			</DialerPopupContainer>
		);
	}
	
	return (
		<DialerPopupContainer>
			<Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'100%'} style={{ padding: '16px' }}
			>
				<Info fontSize='small' style={{ cursor: 'pointer', justifySelf: 'start', color: '#7A7A7A' }}  />
				<Box display={'flex'} flexDirection={'column'} alignItems={'center'} >
					<DialerConnectionText>{dialerStatus ? 'Conectado': 'Desconectado'}</DialerConnectionText>
					<GreenSwitch 
						defaultChecked={dialerStatus} 
						value={dialerStatus} 
						onChange={handleConnection} 
					/>
				</Box>
				<Close onClick={handleClose} style={{ cursor: 'pointer', justifySelf: 'start', color: '#7A7A7A' }} fontSize='small' />
			</Box>
			<Box display={'flex'} flexDirection={'column'} justifyContent={callInProgress && 'space-around'} flexGrow={callInProgress && 1} width={'100%'} alignItems={'center'} style={{ gap: '16px', marginTop: '40px', padding: callInProgress && '16px' }}>
				<Box display={'flex'} flexDirection={'column'} width={'100%'} alignItems={'center'} style={{ gap: '16px' }} >
					<DialerStatusTitle>{!callInProgress ? 'Aguardando' : isConnecting ? 'Conectando...' : 'Em chamada'}</DialerStatusTitle>
					<DialerNumberDisplay>{callNumber !== '' && callNumber ? callNumber : 'Digite um número para realizar a ligação'}</DialerNumberDisplay>
				</Box>
				{callInProgress && <DialerTimer>{getTimerFormatted()}</DialerTimer>}
				<CallActions />
			</Box>
			{!callInProgress && <Keyboard />}
		</DialerPopupContainer>
	);
};

export default DialerPopup;