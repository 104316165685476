import { auth } from 'api/credentials';
import { getProfileType, getTenantId } from 'util/isAdmin';

export function useFetchMenu() {
	const tenantId = getTenantId();
	const profileId = getProfileType();

	const  fetchMenu  = async ( ) => {
		if ( !tenantId ) {
			return [];
		}
		try {
			const response =  await auth.listRoutesByPerfil( { profileId, tenantId } );
			return response.data;
		} catch ( error ) {
			if ( error instanceof Error ) {
				console.error( 'fetchMenu', error.message );
			}
		}
	};
	return {
		fetchMenu
	};
}
