import styled from 'styled-components';

export const NotificationCardSkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  padding: 24px;

  gap: 8px;

  .icon-section {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 20%;

    .icon-section__background {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #626263;
      border-radius: 50%;

      width: 35px;
      height: 35px;
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;

    width: 70%;
    
    gap: 4px;

  }

  .action-section {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 10%;

    .action-section__btn {
      width: 18px;
      height: 18px;

      border-radius: 50%;
    }
  }
`;