import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clients } from 'api/clients';
import {
  CLIENT_EDIT,
  CLIENT_FIND,
  CLIENT_REGISTER,
  CLIENT_REMOVE
} from 'constants/ActionTypes';
import {
  // clientEdit,
  // clientFind,
  // clientRegister,
  showAuthMessage
} from 'actions/Clients';
import { progressActions } from '../actions';

function* removeClientRequest ( payload )  {

  yield clients.remove( payload,{ data: { id: payload } } );
  yield put( progressActions( { percent: false, message: '' } ) );

}

const editClientByIdRequest = async ( payload ) =>
  await clients.edit( payload )
    .then( body => {
      if( body.data.status === 'error' ) {
        throw body.data.message;
      }
      // console.log(body.data);
      return body.data;
    } );

const findClientByIdRequest = async ( payload ) =>
  await clients.find( payload )
    .then( body => {
      if( body.data.status === 'error' ) {
        throw body.data.message;
      }
      return body.data;
    } );

const registerClientIdRequest = async ( payload ) =>
  await clients.register( payload )
    .then( body => {
      if( body.data.status === 'error' ) {
        throw body.data.message;
      }
      return body.data;
    } );



function* removeClientId( { payload } ){
  try{
    yield put( progressActions( { percent: true, message: '' } ) );
    yield call( removeClientRequest, payload );


  } catch( error ){
    yield put( showAuthMessage( error ) );
  }
}

function* editClientById( { payload } ) {
  try {
    const response = yield call( editClientByIdRequest, payload );
    if ( response.message ) {
      yield put( showAuthMessage( response.message ) );
    } else {
      yield put( showAuthMessage( 'Cliente editado com sucesso.' ) );
    }
  } catch ( error ) {
    yield put( showAuthMessage( error ) );
  }
}

function* findClientById( { payload } ) {
  try {
    const response = yield call( findClientByIdRequest, payload );
    console.log( response );
    if ( response.message ) {
      yield put( showAuthMessage( response.message ) );
    } else {
      yield put( showAuthMessage( 'Encontrado cliente (teste).' ) );
    }
  } catch ( error ) {
    yield put( showAuthMessage( error ) );
  }
}

function* registerClientId( { payload } ) {
  try {
    const response = yield call( registerClientIdRequest, payload );
    if ( response.message ) {
      yield put( showAuthMessage( response.message ) );
    } else {
      // userSignUpSuccess();
      yield put( showAuthMessage( 'Cliente cadastrado com sucesso.' ) );
    }
  } catch ( error ) {
    yield put( showAuthMessage( error ) );
  }
}

export function* removeClient(){
  yield takeEvery( CLIENT_REMOVE, removeClientId );
}

export function* editClient() {
  yield takeEvery( CLIENT_EDIT, editClientById );
}

export function* findClient() {
  yield takeEvery( CLIENT_FIND, findClientById );
}

export function* registerClient() {
  yield takeEvery( CLIENT_REGISTER, registerClientId );
}

export default function* rootSaga() {
  yield all( [fork( editClient ),
    fork( removeClient ),
    fork( findClient ),
    fork( registerClient )] );
}
