import React from 'react';
import { useWhatsappGroups } from '../hooks/useWhatsappGroups';
import { MenuItem } from '@material-ui/core';
import { ExpandLess, ExpandMore, WhatsApp } from '@material-ui/icons';
import * as S from './styles';
import { useQuery } from 'react-query';
import LoadingDisplay from 'newComponents/LoadingDisplay';

const WhatsappCollapseGroupList = () => {
	const classes = S.useStyles();
	const {
		openWhatsappList,
		handleOpenWhatsappList,
		fetchWhatsappGroupList,
		handleRedirectToGroup,
	} = useWhatsappGroups();

	const { data, isLoading } = useQuery( {
		queryKey : [ 'whatsappGroupList' ],
		queryFn  : fetchWhatsappGroupList,
	} );

	const whatsappGroupList = data?.filter( group => {
		if ( group?.linkWhatsapp && group?.linkWhatsapp.includes( 'https' ) ) {
			return true;
		}
		return false;
	} );

	const isInvalidGroupList = whatsappGroupList?.length < 1;

	if ( isLoading ) {
		return (
			<MenuItem className={classes.menuItem} button onClick={handleOpenWhatsappList}>
				<WhatsApp  style={{ color: '#25D366' }} />
				Whatsapp por empresa
				<LoadingDisplay />
			</MenuItem>
		);
	}
  
	return (
		<>
			<MenuItem className={classes.menuItem} button onClick={handleOpenWhatsappList}>
				<WhatsApp  style={{ color: '#25D366' }} />
				Whatsapp por empresa
				{openWhatsappList ? <ExpandLess style={{ justifySelf: 'flex-end' }} /> : <ExpandMore style={{ justifySelf: 'flex-end' }} />}
			</MenuItem>
			{openWhatsappList ? (
				isInvalidGroupList ? 
					( <MenuItem style={{ color: '#626263' }}>{'Nenhum link cadastrado'}</MenuItem> ) :
					whatsappGroupList?.map( ( subclient ) => {
						return (
							<MenuItem key={subclient?.id} button className={classes.menuItem} onClick={()=> handleRedirectToGroup( subclient?.linkWhatsapp )}>
								<WhatsApp style={{ color: '#25D366' }} />
								{subclient?.name}
							</MenuItem>
						);
					} )
			) : ( <></> )}
		</>
	);
};

export default WhatsappCollapseGroupList;