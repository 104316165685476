import { bots } from "api/bots";
import { all, fork, put, takeEvery } from "@redux-saga/core/effects";
import { setHistoryReducer } from "actions/Bots";

function* getHistoryBots({ payload }) {
  const response = yield bots.get(payload);
  if (response.data.history) {
    yield put(setHistoryReducer(response.data?.history));
  }
}

export function* fetchBots() {
  yield takeEvery("FETCH_BOTS", getHistoryBots);
}

export default function* rootSaga() {
  yield all([fork(fetchBots)]);
}
